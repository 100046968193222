import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Collapse,
  Typography,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  Business as BusinessIcon,
  Email as EmailIcon,
  AttachMoney as AttachMoneyIcon,
  Assignment as AssignmentIcon,
  Paid as PaidIcon,
  Assessment as AssessmentIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  Build as BuildIcon,
  Layers as LayersIcon,
  ExpandLess,
  ExpandMore,
  HighlightAltTwoTone as HighlightAltTwoToneIcon,
  ManageAccounts as ManageAccountsIcon,
  HelpOutline as HelpOutlineIcon,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import SettingsIcon from "@mui/icons-material/Settings";

function SideBar({ open, toggleDrawer, handleContentType }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openMenus, setOpenMenus] = useState({});
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    if (open) {
      setOpenMenus({});
      setShowInfo(false);
    }
    if (!open) {
      const timer = setTimeout(() => {
        setShowInfo(true);
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [open]);

  const handleClick = (menu) => {
    setOpenMenus((prevState) => ({ ...prevState, [menu]: !prevState[menu] }));
  };

  const handleMenu = (menu) => {
    if (!open) {
      toggleDrawer();
      handleContentType(menu);
    }
  };

  const menuItems = [
    { text: "Dashboard", icon: <DashboardIcon sx={{ width: "20px", height: "20px" }} /> },
    { text: "Enquiry", icon: <HelpOutlineIcon sx={{ width: "20px", height: "20px" }} /> },
    { text: "Organization", icon: <BusinessIcon sx={{ width: "20px", height: "20px" }} /> },
    { text: "E-Mail", icon: <EmailIcon sx={{ width: "20px", height: "20px" }} /> },
    { text: "Rate Management", icon: <AttachMoneyIcon sx={{ width: "20px", height: "20px" }} /> },
    { text: "My Assignments", icon: <AssignmentIcon sx={{ width: "20px", height: "20px" }} /> },
    { text: "Transactions", icon: <PaidIcon sx={{ width: "20px", height: "20px" }} /> },
    { text: "Reports", icon: <AssessmentIcon sx={{ width: "20px", height: "20px" }} /> },
    { text: "Administration", icon: <AdminPanelSettingsIcon sx={{ width: "20px", height: "20px" }} /> },
    { text: "Tools", icon: <BuildIcon sx={{ width: "20px", height: "20px" }} /> },
    {
      text: "Master",
      icon: <LayersIcon sx={{ width: "20px", height: "20px" }} />,
      hasDropdown: true,
      subItems: [
        { Label: "Country", text: "CountryMaster", icon: <HighlightAltTwoToneIcon sx={{ width: "20px", height: "20px" }} /> },
        { Label: "State", text: "StateMaster", icon: <HighlightAltTwoToneIcon sx={{ width: "20px", height: "20px" }} /> },
        { Label: "City", text: "CityMaster", icon: <HighlightAltTwoToneIcon sx={{ width: "20px", height: "20px" }} /> },
      ],
    },
    {
      text: "Settings",
      icon: <SettingsIcon sx={{ width: "20px", height: "20px" }} />,
      hasDropdown: true,
      subItems: [
        { Label: "Profile Update", text: "ProfileUpdate", icon: <ManageAccountsIcon sx={{ width: "20px", height: "20px" }} /> },
      ],
    },
  ];

  const DrawerList = (
    <Box
      sx={{
        backgroundColor: "#00004D",
        color: "white",
        width: open ? (isMobile ? 0 : 57) : 270,
        minHeight: "80vh",
        maxHeight: "calc(100vh - 120px)", // Ensures the sidebar doesn't exceed the viewport height minus any top offset
        overflowY: "auto", // Allow vertical scrolling
        overflowX: "hidden", // Hide horizontal scrolling
        transition: "width .2s",
        scrollBehavior: "smooth",
        "&::-webkit-scrollbar": {
          width: "8px", // Width of scrollbar in WebKit browsers
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#ffffff", // Color of scrollbar thumb
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#dddddd", // Color of scrollbar thumb on hover
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#00004D", // Color of scrollbar track
        },
      }}
      role="presentation"
    >
      <List>
        {menuItems.map((item) => (
          <React.Fragment key={item.text}>
            <ListItem sx={{ paddingTop: "8px" }} disablePadding>
              <ListItemButton
                sx={{
                  paddingY: "10px",
                  borderRadius: "5px",
                  "&:hover": {
                    color: "black",
                    backgroundColor: "#E1ECF9",
                    "& .MuiListItemIcon-root": {
                      color: "black",
                    },
                    "& .expand-icon": {
                      color: "black",
                    },
                  },
                }}
                onClick={
                  item.hasDropdown && !open
                    ? () => handleClick(item.text)
                    : () => handleMenu(item.text)
                }
              >
                <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={item.text}
                  disableTypography
                  sx={{
                    fontFamily: "Times New Roman",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                />
                {item.hasDropdown ? (
                  openMenus[item.text] ? (
                    <ExpandLess className="expand-icon" sx={{ color: "white" }} />
                  ) : (
                    <ExpandMore className="expand-icon" sx={{ color: "white" }} />
                  )
                ) : null}
              </ListItemButton>
            </ListItem>
            {item.hasDropdown && (
              <Collapse in={openMenus[item.text]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ paddingLeft: '16px' }}>
                  {item.subItems.map((subItem) => (
                    <ListItemButton
                      key={subItem.text}
                      sx={{
                        paddingY: "8px",
                        borderRadius: "5px",
                        "&:hover": {
                          color: "black",
                          backgroundColor: "#E1ECF9",
                          "& .MuiListItemIcon-root": {
                            color: "black",
                          },
                        },
                      }}
                      onClick={
                        item.hasDropdown && !open
                          ? () => handleMenu(subItem.text)
                          : null
                      }
                    >
                      <ListItemIcon
                        sx={{ color: "white", paddingLeft: "23px" }}
                      >
                        {subItem.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={subItem.Label}
                        disableTypography
                        sx={{
                          fontFamily: "Times New Roman",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <Drawer
      variant={isMobile ? (!open ? "temporary" : "persistent") : "permanent"}
      sx={{
        position: "fixed",
        top: "80px",
        "& .MuiDrawer-paper": {
          top: "80px",
          backgroundColor: "#00004D",
          color: "white",
          border: "none",
          width: open ? (isMobile ? 0 : 57) : 270,
          minHeight: "100%",
          overflow: "hidden",
        },
      }}
      open={open}
      onClose={() => toggleDrawer()}
    >
      {DrawerList}
      {showInfo && (
        <div
          style={{
            color: "white",
            textAlign: "center",
            opacity: "0.7",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: "13px",
              fontWeight: "bold",
              fontFamily: "Times New Roman",
            }}
          >
            Version 0.0.1
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "13px",
              fontWeight: "bold",
              fontFamily: "Times New Roman",
            }}
          >
            8WAYS GROUP | Shipping&nbsp;&&nbsp;Logistics
          </Typography>
        </div>
      )}
    </Drawer>
  );
}

export default SideBar;
