import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  DialogContentText,
} from "@mui/material";
import Papa from "papaparse";
import axios from "axios";
import { apiUrl } from "../../apiConfig";
import { useSelector } from "react-redux";
import { LinearProgress, useMediaQuery, useTheme } from "@material-ui/core";
import '../../index.css'
const CountryExcelUpload = ({ onChange }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [tableData, setTableData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const rowsPerPage = 500;
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isDragging, setIsDragging] = useState(false);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setError("");
    parseFile(selectedFile);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const selectedFile = event.dataTransfer?.files?.[0];
    setFile(selectedFile);
    setError("");
    parseFile(selectedFile);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const parseFile = (selectedFile) => {
    if (!selectedFile) {
      setError("Please select a file.");
      return;
    }

    Papa.parse(selectedFile, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        console.log("Parsed result:", result);

        // Check if required columns exist
        const requiredColumns = [
          "id",
          "name",
          "iso2",
          "iso3",
          "phone_code",
          "capital",
          "currency",
          "currency_name",
        ];
        if (
          !result.meta.fields.every((field) => requiredColumns.includes(field))
        ) {
          setError("Required columns are missing.");
          return;
        }

        // Filter data based on required columns
        const filteredData = result.data.map((row) => {
          let filteredRow = {};
          requiredColumns.forEach((col) => {
            filteredRow[col] = row[col] || ""; // Set empty string if column is missing
          });
          return filteredRow;
        });

        console.log("Filtered data:", filteredData);

        setTableData(filteredData);
        setDisplayedData(filteredData);
        setOpen(true); // Open dialog box
      },
      error: (err) => {
        setError("Error parsing CSV file.");
        console.error(err);
      },
    });
  };

  const fetchMoreData = () => {
    if (displayedData.length >= tableData.length) {
      setHasMore(false);
      return;
    }

    setTimeout(() => {
      const nextDisplayedData = tableData.slice(
        displayedData.length,
        displayedData.length + rowsPerPage
      );
      console.log("Next displayed data:", nextDisplayedData);
      setDisplayedData((prevDisplayedData) => [
        ...prevDisplayedData,
        ...nextDisplayedData,
      ]);
    }, 100);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      // Check if the file already exists in the DataTable
      const existingFile = await checkExistingFile(file.name);
      if (existingFile) {
        setDuplicateDialogOpen(true);
        return; // Exit function to prevent further execution
      }

      const requestData = displayedData.map((item) => ({
        country_id: item?.id || "",
        country_name: item.name || "",
        country_iso2: item.iso2 || "",
        country_iso3: item.iso3 || "",
        country_phno_code: item.phone_code || "",
        country_capital: item.capital || "",
        country_currency: item.currency || "",
        country_currencyname: item.currency_name || "",
      }));

      console.log("Checked data:", requestData);

      const response = await axios.post(
        `${apiUrl}/countrymaster/`,
        {
          apitype: "Post",
          data: requestData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.data.success) {
        setSuccessDialogOpen(true);
        setOpen(false);
      }
      if (response.data.success === false) {
        console.log("Error saving data:", response.data.error_entries);
        setDuplicateDialogOpen(true);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error saving data:", error);
      if (error.error_entries) {
        console.error("Response data:", error.response.data);
      }

      setIsLoading(false);
    }
  };
  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
    onChange();
  };

  const checkExistingFile = async (fileName) => {
    try {
      // Implement your actual logic to check if fileName already exists
      // For simplicity, assuming a synchronous check based on file name
      return tableData.some((item) => item.name === fileName);
    } catch (error) {
      console.error("Error checking existing file:", error.message);
      return false; // Return false by default on error
    }
  };

  return (
    <div>
      {/* <TextField
        type="file"
        inputProps={{ accept: ".csv" }}
        onChange={handleFileChange}
        variant="outlined"
        style={{ width: "230px" }}
      /> */}
    <form className="file-upload-form">
      <label
        htmlFor="file"
        className={`file-upload-label ${isDragging ? 'dragging' : ''}`}
        style={{
          width: isMobile ? '250px' : '500px',
          height: isMobile ? '200px' : '',
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <div className="file-upload-design">
          <svg viewBox="0 0 640 512" height="1em">
            <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
          </svg>
          <p>Drag and Drop Attachment</p>
          <p>or</p>
          <span className="browse-button">Browse file</span>
        </div>
        <input
          id="file"
          type="file"
          accept=".csv"
          onChange={handleFileChange}
        />
      </label>
    </form>
      {error && <p style={{ color: "red" }}>{error}</p>}

      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>Uploaded Data</DialogTitle>
        {isLoading && (
          <>
            <LinearProgress />
            <p style={{ textAlign: "center" }}>
              Uploading...Please wait for a while
            </p>
          </>
        )}
        {!isLoading && (
          // <DialogContent>
          //   {displayedData.length > 0 && (
          //     <InfiniteScroll
          //       dataLength={displayedData.length}
          //       next={fetchMoreData}
          //       hasMore={hasMore}
          //       loader={<CircularProgress />}
          //       height={600}
          //       endMessage={
          //         <p style={{ textAlign: "center" }}>
          //           <b>Yay! You have seen it all</b>
          //         </p>
          //       }
          //     >
          //       <Paper>
          //         <Table>
          //           <TableHead>
          //             <TableRow>
          //               {Object.keys(displayedData[0]).map((key) => (
          //                 <TableCell
          //                   key={key}
          //                   style={{
          //                     borderBottom: "1px solid black",
          //                     textTransform: "uppercase",
          //                     backgroundColor: "#003380",
          //                     color: "white",
          //                   }}
          //                 >
          //                   {key.replace(/_/g, " ")}
          //                 </TableCell>
          //               ))}
          //             </TableRow>
          //           </TableHead>
          //           <TableBody>
          //             {displayedData.map((row, rowIndex) => (
          //               <TableRow key={rowIndex}>
          //                 {Object.values(row).map((value, cellIndex) => (
          //                   <TableCell
          //                     key={cellIndex}
          //                     style={{ borderBottom: "1px solid black" }}
          //                   >
          //                     {value}
          //                   </TableCell>
          //                 ))}
          //               </TableRow>
          //             ))}
          //           </TableBody>
          //         </Table>
          //       </Paper>
          //     </InfiniteScroll>
          //   )}
          // </DialogContent>
          <DialogContent>
            <p style={{ textAlign: "center" }}>
              You are about to upload {displayedData.length} records.
            </p>
          </DialogContent>
        )}
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            onClick={() => setOpen(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Error Dialog for Existing File */}
      <Dialog
        open={duplicateDialogOpen}
        onClose={() => setDuplicateDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Issue in Uploading Data
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The data you are trying to save has an Issue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDuplicateDialogOpen(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Dialog */}
      <Dialog open={successDialogOpen} onClose={handleSuccessDialogClose}>
        <DialogTitle>Details Added Successfully</DialogTitle>
        <DialogContent>
          <p>Your details have been successfully added.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CountryExcelUpload;
