import React, { useEffect, useState, useRef, useCallback } from "react";
import { Button, Typography, Box, Divider, TextField, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import $ from "jquery";
import "datatables.net";
import "datatables.net-buttons-dt";
import "jszip";
import "pdfmake";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import axios from "axios";
import { apiUrl } from "../../apiConfig";
import OrganizationDetails from "./OrganizationDetails";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const Organization = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const tableRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isNewContentVisible, setIsNewContentVisible] = useState(true);
  const modalRef = useRef(null);
  const accessToken = useSelector((state) => state.auth.accessToken);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/organizationdetails/`,
        {
          apitype: "Get",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const responseData = response.data;
      setData(responseData.data);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching data: " + error.message);
      setLoading(false);
    }
  };


  const handleDelete = useCallback(
    async (OrganizationDetailsId) => {
      try {
        const response = await axios.post(
          `${apiUrl}/organizationdetails/`,
          {
            apitype: "Delete",
            organization_id: OrganizationDetailsId,
            status: "0",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (!response.data.success) {
          throw new Error("Failed to delete data");
        }

        fetchData();
        if ($.fn.DataTable.isDataTable(tableRef.current)) {
          const dataTable = $(tableRef.current).DataTable();
          dataTable.clear().rows.add(data).draw();
        }
      } catch (error) {
        console.error("Error deleting data:", error);
      }
    },
    [data]
  );

  const handleEdit = (item) => {
    setSelectedItem(item);
    setIsNewContentVisible(false);
  };

  const handleDeleteConfirmation = useCallback((item) => {
    setSelectedItem(item);
    setIsDeleteDialogOpen(true);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!loading && $.fn.DataTable.isDataTable(tableRef.current)) {
      const dataTable = $(tableRef.current).DataTable();
      dataTable.clear().rows.add(data).draw();
    }
  }, [data, loading]);

  useEffect(() => {
    if (
      !loading &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        data: data,
        colReorder: true,
        layout: {
          topStart: "search",
          topEnd: "buttons",
        },
        buttons: [
          { extend: "copy", className: "primary-button", text: '<span style="display: flex;align-items: center;"><span style="margin-right: 6px">Copy</span><i class="material-icons" style="font-size: 18px;margin-right: 1px">content_copy</i></span>' },
          { extend: "excel", className: "primary-button", text: '<span style="display: flex; align-items: center;"><span style="margin-right: 6px;">Excel</span><i class="material-icons" style="font-size: 18px; margin-right: 1px;">description</i></span>' },
          { extend: "pdf", className: "primary-button", text: '<span style="display: flex; align-items: center;"><span style="margin-right: 6px;">PDF</span><i class="material-icons" style="font-size: 18px; margin-right: 1px;">picture_as_pdf</i></span>' },
          { extend: "print", className: "primary-button", text: '<span style="display: flex; align-items: center;"><span style="margin-right: 6px">Print</span><i class="material-icons" style="font-size: 18px; margin-right: 1px">print</i></span>' },
        ],
        language: {
          search: "",
          searchPlaceholder: "Search...",
        },
        columns: [
          {
            data: null,
            title: "Sl&nbsp;No",
            width: "5%",
            render: (data, type, row, meta) => meta.row + 1,
          },
          { data: "OrganizationDetailsId", title: "ID", width: "5%", visible: false },
          { data: "CategoryTypeName", title: "Category&nbsp;Name", width: "15%" },
          { data: "CategoryStatus", title: "Category&nbsp;Status", width: "10%" },
          { data: "Location", title: "Location", width: "10%" },
          { data: "ContactPersonName", title: "ContactPersonName", width: "10%" },
          { data: "Designation", title: "Designation", width: "10%" },
          { data: "MobileNo", title: "MobileNo", width: "10%" },
          { data: "Email", title: "Email", width: "10%" },
          {
            data: null,
            title: "Action",
            width: "15%",
            render: (data, type, row) => {
              return `
                                <button class="edit-btn" data-id="${row.OrganizationDetailsId}"><i class="material-icons">edit</i></button>
                                <button class="delete-btn" data-id="${row.OrganizationDetailsId}"><i class="material-icons">delete</i></button>
                            `;
            },
          },
        ],
        rowCallback: function (row, data, index) {
          const backgroundColor = index % 2 === 0 ? "#e6f2ff" : "#ffffff";
          const hoverColor = "#80bfff";
          $(row).css({
            "background-color": backgroundColor,
            transition: "background-color 0.3s ease",
            cursor: "pointer",
          });

          $(row).hover(
            function () {
              $(this).css("background-color", hoverColor);
            },
            function () {
              $(this).css("background-color", backgroundColor);
            }
          );
        },
        drawCallback: function () {
          const currentData = this.api().rows().data().toArray();
          $(".edit-btn").on("click", function () {
            const id = $(this).data("id");
            const item = currentData.find((d) => d.OrganizationDetailsId === id);
            handleEdit(item);
          });

          $(".delete-btn").on("click", function () {
            const id = $(this).data("id");
            console.log("id is :", id);
            const item = currentData.find((d) => d.OrganizationDetailsId === id);
            console.log("item is :", item);
            handleDeleteConfirmation(item);
          });
        },
      });

      $(".primary-button").css({
        "background-color": "#007bff",
        color: "white",
      });

      $('.dataTables_filter input[type="search"]').addClass(
        "MuiInputBase-input"
      );
    }
  }, [loading, data, handleDelete, handleDeleteConfirmation]);

  const handleDeleteConfirmed = () => {
    handleDelete(selectedItem.OrganizationDetailsId);
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteCancelled = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleNewButtonClick = () => {
    setSelectedItem(null);
    // setIsModalOpen(true);
    setIsNewContentVisible(false);
  };

  const handleCountryDetailsClose = () => {
    setIsNewContentVisible(true);
    fetchData();
  };

  return (
    <>
      {isNewContentVisible && (
        <div style={{ width: "90%", backgroundColor: "white", padding: "20px", borderRadius: isMobile ? "0" : "15px", minHeight: "600px", marginTop: "120px", marginLeft: isMobile ? "3px" : "65px", marginRight: isMobile ? "3px" : "50px", marginBottom: "50px", }} >
          <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="20px" >
            <Typography variant="h4" component="h2" style={{ fontFamily: "Times New Roman", fontSize: "22px", color: "rgb(94, 53, 177)", fontWeight: "bold", }} >
              Organization
            </Typography>
            <Button variant="contained" color="primary" onClick={handleNewButtonClick} >
              New
              <AddCircleOutlineIcon sx={{ marginRight: "5px", ml: 1 }} />
            </Button>
          </Box>
          <Divider style={{ marginBottom: "20px" }} />
          <div style={{ marginTop: "20px", maxWidth: isMobile ? "340px" : "100%", overflowX: "auto", }} >
            <table ref={tableRef} className="display" style={{ width: "100%", border: "1px solid #003380" }} >
              <thead style={{ backgroundColor: "#00004d", color: "white" }}>
                <tr>
                  <th>Sl No</th>
                  <th>ID</th>
                  <th>Category&nbsp;Name</th>
                  <th>Category&nbsp;Status</th>
                  <th>Location</th>
                  <th>ContactPersonName</th>
                  <th>Designation</th>
                  <th>MobileNo</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      )}
      {!isNewContentVisible && (
        <OrganizationDetails onClose={handleCountryDetailsClose} item={selectedItem} />
      )}
      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancelled}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this item?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmed} color="error">
            Yes
          </Button>
          <Button onClick={handleDeleteCancelled} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Organization;
