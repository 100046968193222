import { useTheme } from '@emotion/react';
import { Box, Container, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import CardComponent from '../Components/CardComponent';

export default function DashBoard() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const handleButtonClick = (cardId) => {
      console.log(`Button clicked on card ${cardId}`);
    };
    return (
      <>
        <div
          style={{
            width: "90%",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: isMobile ? "0" : "15px",
            minHeight: "750px",
            marginTop: "120px",
            marginLeft: isMobile ? "3px" : "65px",
            marginRight: isMobile ? "3px" : "50px",
            marginBottom: "30px",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom="20px"
          >
            <Typography
              variant="h4"
              component="h2"
              style={{
                fontFamily: "Times New Roman",
                fontSize: "22px",
                color: "rgb(94, 53, 177)",
                fontWeight: "bold",
              }}
            >
              DashBoard
            </Typography>
    
          </Box>
          <Divider style={{ marginBottom: "20px" }} />
          <Container>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                <CardComponent
                  title="#3C4F77"
                  count="4"
                  content='Card - 1'
                  subcontent='Test card one'
                  buttonText="Learn More"
                  icons="PostAddIcon"
                  onButtonClick={() =>handleButtonClick(3)}
                />
                </Grid>
           
                <Grid item xs={12} sm={6} md={3}>
                <CardComponent
                    title="#C70039"
                    count="8"
                    content='Card - 2'
                    subcontent='Test card two'
                    buttonText="Learn More"
                    icons="FactCheckIcon"
                    onButtonClick={() => handleButtonClick(3)}
                />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                <CardComponent
                    title="#900C3F"
                    count="8"
                    content='Card - 3'
                    subcontent='Test card three'
                    buttonText="Learn More"
                    icons="FileUploadIcon"
                    onButtonClick={() => handleButtonClick(3)}
                />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                <CardComponent
                    title="#581845"
                    count="7"
                    content='Card - 4'
                    subcontent='Test card four'
                    buttonText="Learn More"
                    icons="DepartureBoardIcon"
                    onButtonClick={() => handleButtonClick(3)}
                />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                <CardComponent
                    title="#2BAE7C"
                    count="45"
                    content='Card - 5'
                    subcontent='Test card five'
                    buttonText="Learn More"
                    icons="AccessTimeIcon"
                    onButtonClick={() => handleButtonClick(3)}
                />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                <CardComponent
                    title="#5499C7"
                    count="68"
                    content='Card - 6'
                    subcontent='Test card six'
                    buttonText="Learn More"
                    icons="CallReceivedIcon"
                    onButtonClick={() => handleButtonClick(3)}
                />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                <CardComponent
                    title="#7DCEA0"
                    count="8"
                    content='Card - 7'
                    subcontent='Test card seven'
                    buttonText="Learn More"
                    icons="PendingActionsIcon"
                    onButtonClick={() => handleButtonClick(3)}
                />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                <CardComponent
                    title="#76D7C4"
                    count="8"
                    content='Card - 8'
                    subcontent='Test card eight'
                    buttonText="Learn More"
                    icons="SupportAgentIcon"
                    onButtonClick={() => handleButtonClick(3)}
                />
                </Grid>
            </Grid>
        </Container>
        </div>
        <Divider sx={{ marginBottom: "20px",ml:"65px",boxShadow: 7,borderColor: 'black',mr:"30px", }} />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginBottom="20px"
          sx={{ marginTop: "auto" }}
        >
          <Typography variant="p" component="p" sx={{ fontSize: "12px" ,opacity:0.7}}  >Copyright © 2024 | 8 WAYS GROUP.&nbsp;&nbsp;All Rights Reserved</Typography>
        </Box>
      
      </>
    )
}