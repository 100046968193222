import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import React from 'react'
import '../index.css'
import PageNotFound from  '../assets/images/pagenotfound.png'
function PageNotFount() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
    <div style={{
        width: "90%",
        backgroundColor: "white",
        borderRadius: isMobile ? "0" : "15px",
        height: "85vh",
        marginTop: "95px",
        marginLeft: isMobile ? "3px" : "90px",
        marginRight: isMobile ? "3px" : "50px",
      }}
    >
        {/* <Typography variant="h5" component="h2" sx={{color: "#00003D", textAlign: "center", fontWeight: "bold",marginTop: "50px" }}>
          Work On Progress
        </Typography>
      
        <div class="rocket" style={{marginTop: "130px"}}>
            <div class="rocket-body" >
            <div class="body"></div>
            <div class="fin fin-left"></div>
            <div class="fin fin-right"></div>
            <div class="window"></div>
            </div>
            <div class="exhaust-flame"></div>
            <ul class="exhaust-fumes">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div> */}
        <img src={PageNotFound} alt="404" style={{width: "100%", height: "100%" ,borderRadius: "10px" }} />
      
    </div>

    
    </>
  )
}

export default PageNotFount