import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LOGO from "../../assets/images/Logo_8way.png";
import LOGINLOGO from "../../assets/images/Logo_8way.png";
import loginbackground from "../../assets/images/Login_background.png";
import "./LoginPage.css";
import { useTheme } from "@emotion/react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Typography, useMediaQuery } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import axios from "axios";
import { apiUrl } from "../../apiConfig";
import { useDispatch } from "react-redux";
import { setTokens } from "../../Redux/actions/authActions";
import { setUser } from "../../Redux/actions/userActions";

const Login = ({ setSnackbarMessage, setSnackbarColor, setOpenSnackbar }) => {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const loginButtonRef = useRef(null);
  const dispatch = useDispatch();
  const dialogRef = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleInputChange = (e) => {
    if (e.target.id === "outlined-adornment-username") {
      setUsername(e.target.value);
      setUsernameError(false); // Clear username error when user starts typing
    } else if (e.target.id === "outlined-adornment-password") {
      setPassword(e.target.value);
      setPasswordError(false); // Clear password error when user starts typing
    }
  };

  const handleLoginReset = async (e) => {
    try {
      setIsDialogOpen(false);
      setLoading(true);
      const response = await axios.post(
        `${apiUrl}/tokenreset/`,
        {
          email: username,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status) {
        handleLogin(e);
      }
    } catch (e) {}
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    // Reset errors
    setUsernameError(false);
    setPasswordError(false);

    // Check for empty fields
    if (username === "" && password === "") {
      setUsernameError(true);
      setPasswordError(true);
      return;
    }

    if (username === "" || password === "") {
      if (password === "") {
        setPasswordError(true);
      }
      if (username === "") {
        setUsernameError(true);
      }
      return;
    }
    setLoading(true);
    if (username !== "" && password !== "") {
      try {
        const response = await axios.post(
          `${apiUrl}/login/`,
          {
            email: username,
            password: password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data);
        const responseData = response.data.data;
        dispatch(setTokens(responseData.access, responseData.refresh));
        const protectedResponse = await axios.post(
          `${apiUrl}/loginprotected/`,
          {},
          {
            headers: {
              Authorization: `Bearer ${responseData.access}`,
            },
          }
        );
        console.log(protectedResponse.data.data);
        dispatch(setUser(protectedResponse.data.data));
        if (protectedResponse.detail) {
          throw new Error("Failed to delete data");
        }
        setSnackbarMessage("Login Successful");
        setSnackbarColor("success");
        setOpenSnackbar(true);
        setLoading(false);
        navigate("/dashboard");
      } catch (error) {
        if (
          error.response.data.non_field_errors[0] !==
          "Invalid email or password."
        ) {
          setLoading(false);
          setDialogMessage(error.response.data.non_field_errors[0]);
          setIsDialogOpen(true);
        } else {
          setLoading(false);
          setSnackbarMessage("Invalid Email or Password");
          setSnackbarColor("error");
          setOpenSnackbar(true);
          setUsername("");
          setPassword("");
          usernameRef.current?.focus();
          console.error("Error data:", error);
        }
      }
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleCancelled = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      {!loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end", // Align the content to the right side
            alignItems: "center",
            height: isMobile ? "100%" : "100vh",
            backgroundImage: `url(${loginbackground})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div
            style={{
              display: "flex",
              // height: "350px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: isMobile ? "10px" : "40px",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              borderRadius: "10px",
              marginRight: "7%",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)", // Add this line for shadow
            }}
          >
            <header style={{ marginTop: "20px", textAlign: "center" }}>
              <img src={LOGINLOGO} alt="Logo" style={{ height: "50px", verticalAlign: "middle" }} />
              <span style={{ verticalAlign: 'middle', display: 'inline-block', fontSize: '25px', fontFamily: 'Times New Roman, Times, serif', fontWeight: 'bold', color: '#003380' }}>8&nbsp;WAYS&nbsp;GROUP</span>
              <h1 style={{ fontFamily: "Times New Roman, Times, serif", fontSize: "20px", fontWeight: "bold", color: "#003380", marginTop: "20px" }}>LOG IN</h1>
            </header>

            <Box component={"form"} onSubmit={(e) => handleLogin(e)} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginTop: "20px" }} >
              <label style={{ fontFamily: "Times New Roman, Times, serif", fontSize: "16px", fontWeight: "bold", color: "#003380", marginRight: "auto", marginLeft: "20px" }} >
                Username
              </label>
              <TextField
                id="outlined-adornment-username"
                placeholder="Eg: abc@abc.com"
                variant="outlined"
                size="small"
                value={username}
                onChange={handleInputChange}
                inputRef={usernameRef}
                helperText={usernameError && "Please enter a valid email"}
                error={usernameError}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircleIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
                sx={{ m: 1, width: "25ch", marginBottom: "20px" }}
              />

              <label style={{ fontFamily: "Times New Roman, Times, serif", fontSize: "16px", fontWeight: "bold", color: "#003380", marginRight: "auto", marginLeft: "20px" }} >
                Password
              </label>
              <TextField
                id="outlined-adornment-password"
                placeholder="Eg: abc123@#$"
                variant="outlined"
                size="small"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handleInputChange}
                inputRef={passwordRef}
                helperText={passwordError && "Please enter a valid password"}
                error={passwordError}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
                sx={{ m: 1, width: "25ch" }}
              />
              <Button
                type="submit"
                variant="contained"
                onClick={handleLogin}
                sx={{ marginTop: "20px", width: "28ch", backgroundColor: "#7CCC16" }}
                color="success"
              >
                Login
              </Button>
              <Typography variant="p" component={Link} sx={{ marginTop: "20px", fontSize: "13px", cursor: "pointer", color: "blue" }}>
                Forgot Password?
              </Typography>
            </Box>
          </div>
          <Dialog
            open={isDialogOpen}
            onClose={handleCancelled}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown
            disableBackdropClick
            ref={dialogRef}
          >
            <DialogTitle id="alert-dialog-title">{"Login Failed"}</DialogTitle>
            <DialogContent>
              <Typography variant="body2" color="textSecondary">
                {dialogMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleLoginReset} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default Login;
