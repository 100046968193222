import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Typography, TextField, FormControl, InputLabel, Select, MenuItem, Grid, Box, Button, FormControlLabel, Checkbox, Autocomplete } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useSelector } from "react-redux";
import { apiUrl } from "../../apiConfig";
import axios from "axios";

import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import SuccessIcon from '../../assets/images/success.gif';
import ErrorIcon from '../../assets/images/failure.gif';

import $ from "jquery";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@emotion/react";
import VendorExcelUpload from "../../Components/ExcelUpload/VendorExcelUpload";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

const Vendor = ({ onClose, item }) => {

    const [selectedItem, setSelectedItem] = useState(item);
    const accessToken = useSelector((state) => state.auth.accessToken);
    const dialogRef = useRef(null);
    const failureRef = useRef(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [failureDialog, setFailureDialog] = useState(false);
    const [expanded, setExpanded] = useState('panel1');
    const [errorMessage, setErrorMessage] = useState('');


    const [vendorStatus, setVendorStatus] = useState('');
    const [vendorType, setVendorType] = useState('');
    const [vendorLocation, setVendorLocation] = useState('');
    const [vendorReference, setVendorReference] = useState('');
    const [errors, setErrors] = useState({});

    const [companyName, setCompanyName] = useState('');
    const [contactPersonName, setContactPersonName] = useState('');
    const [designation, setDesignation] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [telephoneNo, setTelephoneNo] = useState('');
    const [email, setEmail] = useState('');
    const [web, setWeb] = useState('');
    const [error, setError] = useState('');

    const [kycDocuments, setKycDocuments] = useState('');
    const [panNo, setPanNo] = useState('');
    const [gstNo, setGstNo] = useState('');
    const [iecNo, setIecNo] = useState('');

    const [creditDays, setCreditDays] = useState('');
    const [creditLimit, setCreditLimit] = useState('');
    const [creditOnHold, setCreditOnHold] = useState('');
    const [invoiceOverdue, setInvoiceOverdue] = useState('');
    const [creditRemarks, setCreditRemarks] = useState('');

    const [bankName, setBankName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [accountType, setAccountType] = useState('');
    const [ifscCode, setIFSCCode] = useState('');

    const [branchType, setBranchType] = useState('');
    const [branchName, setBranchName] = useState('');
    const [branchAddress, setBranchAddress] = useState('');

    const [consigneeName, setConsigneeName] = useState('');
    const [shipperName, setShipperName] = useState('');

    //--------------------------Start----------------------------------//

    // Initialize states to null initially and update later after data fetch
    const [initialCountry, setInitialCountry] = useState(null);
    const [initialState, setInitialState] = useState(null);
    const [initialCity, setInitialCity] = useState(null);

    const [copyAddress, setCopyAddress] = useState(false);
    const [registerAddress, setRegisterAddress] = useState('');
    const [registerCountry, setRegisterCountry] = useState(null);
    const [registerState, setRegisterState] = useState(null);
    const [registerCity, setRegisterCity] = useState(null);
    const [registerPincode, setRegisterPincode] = useState('');

    const [communicationAddress, setCommunicationAddress] = useState('');
    const [communicationCountry, setCommunicationCountry] = useState(null);
    const [communicationState, setCommunicationState] = useState(null);
    const [communicationCity, setCommunicationCity] = useState(null);
    const [communicationPincode, setCommunicationPincode] = useState('');

    const handleCopyAddress = (event) => {
        const isChecked = event.target.checked;
        setCopyAddress(isChecked);

        if (isChecked) {
            setCommunicationAddress(registerAddress);
            setCommunicationCountry(registerCountry);
            setCommunicationState(registerState);
            setCommunicationCity(registerCity);
            setCommunicationPincode(registerPincode);
        } else {
            // Optionally, clear communication address fields if unchecked
            setCommunicationAddress('');
            setCommunicationCountry(null);
            setCommunicationState(null);
            setCommunicationCity(null);
            setCommunicationPincode('');
        }
    };

    // State for country, state, and city filters
    const [filterCountry, setFilterCountry] = useState(null);
    const [filterState, setFilterState] = useState(null);
    const [filterCity, setFilterCity] = useState(null);
    const [countryData, setCountryData] = useState([]);
    const [filteredStateData, setFilteredStateData] = useState([]);
    const [filteredCityData, setFilteredCityData] = useState([]);
    const [stateFieldDisabled, setStateFieldDisabled] = useState(true);
    const [cityFieldDisabled, setCityFieldDisabled] = useState(true);

    // State for country, state, and city filters
    const [filterCommunicationCountry, setFilterCommunicationCountry] = useState(null);
    const [filterCommunicationState, setFilterCommunicationState] = useState(null);
    const [filterCommunicationCity, setFilterCommunicationCity] = useState(null);
    const [communicationCountryData, setCommunicationCountryData] = useState([]);
    const [filteredCommunicationStateData, setFilteredCommunicationStateData] = useState([]);
    const [filteredCommunicationCityData, setFilteredCommunicationCityData] = useState([]);
    const [stateFieldCommunicationDisabled, setCommunicationStateFieldDisabled] = useState(true);
    const [cityFieldCommunicationDisabled, setCommunicationCityFieldDisabled] = useState(true);


    // Fetch country data
    const fetchDataCountry = useCallback(async () => {
        try {
            const response = await axios.post(`${apiUrl}/countrymaster/`, { apitype: 'Get' }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            });
            const fetchedCountryData = response.data.data;
            setCountryData(fetchedCountryData);
            setCommunicationCountryData(fetchedCountryData);

            // Initialize based on fetched data and selectedItem
            const country = fetchedCountryData.find(country => country.CountryName === selectedItem?.RegisterCountry) || null;

            // If country is found, fetch the state and city based on the country
            if (country) {
                setRegisterCountry(country);
                console.log('Selected Country:', country);

                // Fetch the state for the selected country
                await fetchDataState(country);
            }

        } catch (error) {
            console.error('Error fetching country data:', error.message);
        }
    }, [apiUrl, accessToken, selectedItem]);

    // Fetch state data based on selected country
    const fetchDataState = useCallback(async (selectedCountry) => {
        try {
            const response = await axios.post(`${apiUrl}/statemaster/`, {
                apitype: 'Get',
                country_name: selectedCountry?.CountryName || 'India'
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            });

            const fetchedStateData = response.data.data;
            setFilteredStateData(fetchedStateData);

            // Initialize state based on selectedItem
            const state = fetchedStateData.find(state => state.StateName === selectedItem?.RegisterState) || null;
            if (state) {
                setRegisterState(state);
                console.log('Selected State:', state);

                // Fetch the city for the selected state
                await fetchDataCity(selectedCountry, state);
            }

        } catch (error) {
            console.error('Error fetching state data:', error.message);
        }
    }, [apiUrl, accessToken, selectedItem]);

    // Fetch city data based on selected country and state
    const fetchDataCity = useCallback(async (selectedCountry, selectedState) => {
        try {
            const response = await axios.post(`${apiUrl}/citymaster/`, {
                apitype: 'Get',
                country_name: selectedCountry?.CountryName || 'India',
                state_name: selectedState?.StateName || 'Tamil Nadu'
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            });

            const fetchedCityData = response.data.data;
            setFilteredCityData(fetchedCityData);

            // Initialize city based on selectedItem
            const city = fetchedCityData.find(city => city.CityName === selectedItem?.RegisterCity) || null;
            if (city) {
                setRegisterCity(city);
                console.log('Selected City:', city);
            }

        } catch (error) {
            console.error('Error fetching city data:', error.message);
        }
    }, [apiUrl, accessToken, selectedItem]);

    // Initial data fetch for country
    useEffect(() => {
        fetchDataCountry();
    }, [fetchDataCountry]);



    // Fetch communication country data and initialize state and city
    const fetchCommunicationCountry = useCallback(async () => {
        try {
            const response = await axios.post(`${apiUrl}/countrymaster/`, { apitype: 'Get' }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            });
            const fetchedCountryData = response.data.data;
            setCommunicationCountryData(fetchedCountryData);

            // Initialize based on selectedItem
            const country = fetchedCountryData.find(country => country.CountryName === selectedItem?.CommunicationCountry) || null;

            // If country is found, fetch the state and city based on the country
            if (country) {
                setCommunicationCountry(country);
                console.log('Selected Communication Country:', country);

                // Fetch the state for the selected communication country
                await fetchCommunicationState(country);
            }

        } catch (error) {
            console.error('Error fetching communication country data:', error.message);
        }
    }, [apiUrl, accessToken, selectedItem]);


    // Fetch communication state data based on selected country
    const fetchCommunicationState = useCallback(async (selectedCommunicationCountry) => {
        try {
            const response = await axios.post(`${apiUrl}/statemaster/`, {
                apitype: 'Get',
                country_name: selectedCommunicationCountry?.CountryName || 'India'
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            });

            const fetchedCommunicationStateData = response.data.data;
            setFilteredCommunicationStateData(fetchedCommunicationStateData);

            // Initialize state based on selectedItem for communication address
            const state = fetchedCommunicationStateData.find(state => state.StateName === selectedItem?.CommunicationState) || null;
            if (state) {
                setCommunicationState(state);
                console.log('Selected Communication State:', state);

                // Fetch the city for the selected communication state
                await fetchCommunicationCity(selectedCommunicationCountry, state);
            }

        } catch (error) {
            console.error('Error fetching communication state data:', error.message);
        }
    }, [apiUrl, accessToken, selectedItem]);

    // Fetch communication city data based on selected country and state
    const fetchCommunicationCity = useCallback(async (selectedCommunicationCountry, selectedCommunicationState) => {
        try {
            const response = await axios.post(`${apiUrl}/citymaster/`, {
                apitype: 'Get',
                country_name: selectedCommunicationCountry?.CountryName || 'India',
                state_name: selectedCommunicationState?.StateName || 'Tamil Nadu'
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            });

            const fetchedCommunicationCityData = response.data.data;
            setFilteredCommunicationCityData(fetchedCommunicationCityData);

            // Initialize city based on selectedItem for communication address
            const city = fetchedCommunicationCityData.find(city => city.CityName === selectedItem?.CommunicationCity) || null;
            if (city) {
                setCommunicationCity(city);
                console.log('Selected Communication City:', city);
            }

        } catch (error) {
            console.error('Error fetching communication city data:', error.message);
        }
    }, [apiUrl, accessToken, selectedItem]);

    // Initial data fetch for communication country
    useEffect(() => {
        fetchCommunicationCountry();
    }, [fetchCommunicationCountry]);


    // Country change handler for Register
    const handleCountryChange = (event, value) => {
        setRegisterCountry(value);
        if (value) {
            fetchDataState(value);  // Fetch states for the selected country
            setStateFieldDisabled(false);  // Enable state field
        } else {
            setStateFieldDisabled(true);  // Disable state field
            setFilteredStateData([]);  // Clear state data
            setFilteredCityData([]);  // Clear city data
        }
    };

    // State change handler for Register
    const handleStateChange = (event, value) => {
        setRegisterState(value);
        if (value && registerCountry) {
            fetchDataCity(registerCountry, value);  // Fetch cities based on country and state
            setCityFieldDisabled(false);  // Enable city field
        } else {
            setCityFieldDisabled(true);  // Disable city field
            setFilteredCityData([]);  // Clear city data
        }
    };

    // City change handler for Register
    const handleCityChange = (event, value) => {
        setRegisterCity(value);
    };

    // Country change handler for Communication
    const handleCommunicationCountryChange = (event, value) => {
        setCommunicationCountry(value);
        if (value) {
            // fetchDataState(value);  // Fetch states for the selected country
            fetchCommunicationState(value);  // Fetch states for the selected country
            setCommunicationStateFieldDisabled(false);  // Enable state field
        } else {
            setCommunicationStateFieldDisabled(true);  // Disable state field
            setFilteredCommunicationStateData([]);  // Clear state data
            setFilteredCommunicationCityData([]);  // Clear city data
        }
    };

    // State change handler for Communication
    const handleCommunicationStateChange = (event, value) => {
        setCommunicationState(value);
        if (value && communicationCountry) {
            fetchCommunicationCity(communicationCountry, value);  // Fetch cities based on country and state
            setCommunicationCityFieldDisabled(false);  // Enable city field
        } else {
            setCommunicationCityFieldDisabled(true);  // Disable city field
            setFilteredCommunicationCityData([]);  // Clear city data
        }
    };

    // City change handler for Communication
    const handleCommunicationCityChange = (event, value) => {
        setCommunicationCity(value);
    };



    //----------------------------End--------------------------------//

    // Load selectedItem data on component mount or change
    useEffect(() => {
        if (selectedItem) {
            setVendorStatus(selectedItem?.CategoryStatus || '');
            setVendorType(selectedItem?.VendorType || '');
            setVendorLocation(selectedItem?.Location || '');
            setVendorReference(selectedItem?.Reference || '');

            setCompanyName(selectedItem?.CompanyName || '');
            setContactPersonName(selectedItem?.ContactPersonName || '');
            setDesignation(selectedItem?.Designation || '');
            setMobileNo(selectedItem?.MobileNo || '');
            setTelephoneNo(selectedItem?.TelephoneNo || '');
            setEmail(selectedItem?.Email || '');
            setWeb(selectedItem?.Web || '');

            setKycDocuments(selectedItem?.KYCDocuments || '');
            setPanNo(selectedItem?.PanNo || '');
            setGstNo(selectedItem?.GSTNo || '');
            setIecNo(selectedItem?.IECNo || '');

            setCreditDays(selectedItem.CreditDays || '');
            setCreditLimit(selectedItem.CreditLimit || '');
            setCreditOnHold(selectedItem.CreditOnHold || '');
            setInvoiceOverdue(selectedItem.InvoiceOverdue || '');
            setCreditRemarks(selectedItem.CreditRemarks || '');

            setBankName(selectedItem.BankName || '');
            setAccountNumber(selectedItem.AccountNumber || '');
            setAccountType(selectedItem.AccountType || '');
            setIFSCCode(selectedItem.IFSCCode || '');

            setBranchType(selectedItem.BranchType || '');
            setBranchName(selectedItem.BranchName || '');
            setBranchAddress(selectedItem.BranchAddress || '');

            setConsigneeName(selectedItem.ConsigneeName || '');
            setShipperName(selectedItem.ShipperName || '');

            setRegisterAddress(selectedItem.RegisterAddress || '');
            setRegisterPincode(selectedItem.RegisterPincode || '');

            setCommunicationAddress(selectedItem.CommunicationAddress || '');
            setCommunicationPincode(selectedItem.CommunicationPincode || '');

            console.log("Check communication country : ", selectedItem.CommunicationCountry);
            console.log("Check communication state : ", selectedItem.CommunicationState);
            console.log("Check communication city : ", selectedItem.CommunicationCity);

        }
    }, [selectedItem]);


    // Handle dropdown value changes
    const handleMainChange = (field, value) => {
        console.log(`Field: ${field}, Value: ${value}`); // Add this line to debug
        if (field === 'vendorStatus') setVendorStatus(value);
        if (field === 'vendorType') setVendorType(value);
        if (field === 'vendorLocation') setVendorLocation(value);
        if (field === 'vendorReference') setVendorReference(value);
        console.log("View vendor reference value : ", vendorReference);
    };


    // Handle text field value changes
    const handleUpdateChange = (field, value) => {
        // if (field === 'Reference') {
        //     setCustomerReference(value);
        // }

        console.log(`Field: ${field}, Value: ${value}`); // Add this line to debug

        switch (field) {
            case 'CompanyName':
                setCompanyName(value);
                break;
            case 'ContactPersonName':
                setContactPersonName(value);
                break;
            case 'Designation':
                setDesignation(value);
                break;
            case 'MobileNo':
                setMobileNo(value);
                break;
            case 'TelephoneNo':
                setTelephoneNo(value);
                break;
            case 'Email':
                // Basic email validation
                setEmail(value);
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(value)) {
                    setError('Please enter a valid email address');
                } else {
                    setError('');
                }
                break;
            case 'Web':
                setWeb(value);
                break;
            case 'KYCDocuments':
                setKycDocuments(value);
                break;
            case 'PanNo':
                setPanNo(value);
                break;
            case 'GSTNo':
                setGstNo(value);
                break;
            case 'IECNo':
                setIecNo(value);
                break;
            case 'CreditDays':
                setCreditDays(value);
                break;
            case 'CreditLimit':
                setCreditLimit(value);
                break;
            case 'CreditOnHold':
                setCreditOnHold(value);
                break;
            case 'InvoiceOverdue':
                setInvoiceOverdue(value);
                break;
            case 'CreditRemarks':
                setCreditRemarks(value);
                break;
            case 'BankName':
                setBankName(value);
                break;
            case 'AccountNumber':
                setAccountNumber(value);
                break;
            case 'AccountType':
                setAccountType(value);
                break;
            case 'IFSCCode':
                setIFSCCode(value);
                break;
            case 'BranchType':
                setBranchType(value);
                break;
            case 'BranchName':
                setBranchName(value);
                break;
            case 'BranchAddress':
                setBranchAddress(value);
                break;
            case 'ConsigneeName':
                setConsigneeName(value);
                break;
            case 'ShipperName':
                setShipperName(value);
                break;
            case 'registerAddress':
                setRegisterAddress(value);
                break;
            default:
                break;
        }
    };


    const validate = () => {
        const newErrors = {};

        if (!vendorStatus || vendorStatus === 'Select Vendor Status')
            newErrors.vendorStatus = 'Vendor Status is required';

        if (!vendorType || vendorType === 'Select Vendor Type')
            newErrors.vendorType = 'Vendor Type is required';

        if (!vendorLocation || vendorLocation === 'Select Vendor Location')
            newErrors.vendorLocation = 'Vendor Location is required';


        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleSave = async (e) => {
        e.preventDefault();

        // Validation checks
        if (vendorStatus !== "Temp") {
            if (!validate()) {
                console.log("Form is invalid. Fix errors before saving.");
                return;
            }
        }

        // Validate email
        if (vendorStatus !== "Temp") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                setErrorMessage('Please enter a valid email address');
                setFailureDialog(true);
                return;
            }
        }


        // Detect if customer status is "Temp" for bulk upload
        if (vendorStatus === "Temp" && bulkUpload === true) {
            console.log("Customer status is Temp, initiating bulk upload");

            // const bulkData = data;

            // Add 'CategoryTypeName' to each object in bulkData
            const bulkData = saveData.map(item => ({
                ...item,
                categorytype_name: "Vendor" // Add this field to ensure it's not null
            }));

            try {

                const response = await axios.post(
                    `${apiUrl}/organizationdetails/`,
                    {
                        apitype: "Bulk_Post",
                        data_list: bulkData
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

                console.log("Bulk upload response:", response.data);

                if (!response.data.success) {
                    throw new Error("Failed to bulk upload data");
                }

                setOpenDialog(true); // Show success dialog
                console.log("Bulk data uploaded successfully");
            } catch (error) {
                console.error("Error uploading bulk data:", error);
                setErrorMessage(error.response?.data?.message || 'Unknown error occurred');
                setFailureDialog(true);
            }

            setBulkUpload(false);
            return; // Stop further execution since bulk upload was done
        }


        const apiType = selectedItem?.OrganizationDetailsId ? "Update" : "Post";
        console.log("ApiType is:", apiType);

        // Prepare request data
        let requestData = {
            apitype: apiType,
            organization_id: selectedItem?.OrganizationDetailsId || '', // Ensure this is correct
            categorytype_name: "Vendor",
            category_status: vendorStatus || selectedItem?.CategoryStatus,
            customer_type: "",
            vendor_type: vendorType || selectedItem?.VendorType,
            location: vendorLocation || selectedItem?.Location,
            reference: vendorReference || selectedItem?.Reference,
            company_name: companyName || selectedItem?.CompanyName,
            contact_person_name: contactPersonName || selectedItem?.ContactPersonName,
            designation: designation || selectedItem?.Designation,
            mobile_no: mobileNo || selectedItem?.MobileNo,
            telephone_no: telephoneNo || selectedItem?.TelephoneNo,
            email: email || selectedItem?.Email,
            web: web || selectedItem?.Web,
            register_address: registerAddress || selectedItem?.RegisterAddress || "",
            register_country: registerCountry?.CountryName || selectedItem?.RegisterCountry || "",
            register_state: registerState?.StateName || selectedItem?.RegisterState || "",
            register_city: registerCity?.CityName || selectedItem?.RegisterCity || "",
            register_pincode: registerPincode || selectedItem?.RegisterPincode || "",
            communication_address: communicationAddress || selectedItem?.CommunicationAddress || "",
            communication_country: communicationCountry?.CountryName || selectedItem?.CommunicationCountry || "",
            communication_state: communicationState?.StateName || selectedItem?.CommunicationState || "",
            communication_city: communicationCity?.CityName || selectedItem?.CommunicationCity || "",
            communication_pincode: communicationPincode || selectedItem?.CommunicationPincode || "",
            credit_days: creditDays || selectedItem?.CreditDays || "",
            credit_limit: creditLimit || selectedItem?.CreditLimit || "",
            credit_on_hold: "",
            invoice_overdue: "",
            credit_remarks: "",
            bank_name: selectedItem?.BankName || "",
            account_number: selectedItem?.AccountNumber || "",
            account_type: selectedItem?.AccountType || "",
            ifsc_code: selectedItem?.IfscCode || "",
            branch_type: selectedItem?.BranchType || "",
            branch_name: selectedItem?.BranchName || "",
            branch_address: selectedItem?.BranchAddress || "",
            consignee_name: "",
            shipper_name: ""
        };

        // Add conditional fields based on customerStatus
        if (vendorStatus !== "Temp") {
            const ids = ["registerAddress", "registerPincode", "communicationAddress", "communicationPincode",
                "creditDays", "creditLimit", "bankName", "accountNumber", "accountType",
                "branchType", "branchName", "branchAddress"];

            ids.forEach(id => {
                requestData[id.replace(/([A-Z])/g, "_$1").toLowerCase()] = document.getElementById(id)?.value || "";
            });

            if (vendorLocation !== "USA" && vendorLocation !== "OverSeas") {
                ["kycDocuments", "panNo", "gstNo", "ifscCode"].forEach(id => {
                    requestData[id.replace(/([A-Z])/g, "_$1").toLowerCase()] = document.getElementById(id)?.value || "";
                });
            }
        }

        console.log("Request Data:", requestData);

        // Make API call
        try {
            const response = await axios.post(
                `${apiUrl}/organizationdetails/`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            console.log("Response:", response.data);

            if (!response.data.success) {
                throw new Error("Failed to save data");
            }

            setOpenDialog(true); // Show success dialog
            console.log("Form is valid. Saving data...");
        } catch (error) {
            console.error("Error saving data:", error);
            setErrorMessage(error.response?.data?.message || 'Unknown error occurred');
            setFailureDialog(true);
        }
    };



    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleFailureClose = () => {
        setFailureDialog(false); // Function to close the dialog
    };

    const handleCancelled = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpenDialog(false);
        }
    };



    const tableRef = useRef(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [data, setData] = useState([]);
    const [saveData, setSaveData] = useState([]);
    const [bulkUpload, setBulkUpload] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleFileUpload = (file) => {
        console.log("Test for customer excel data: ", file);

        // Assuming file is an array of objects
        const parsedData = file.map((row, index) => ({

            categorytype_name: '',

            SlNo: index + 1, // or however you want to generate the ID
            CompanyName: row.Company_Name,
            ContactPersonName: row.Contact_Person,
            Designation: row.Designation,
            MobileNumber: row.Mobile_Number,
            TelephoneNumber: row.Tele_Number,
            Website: row.Website,
            EmailId: row.Email_ID,
            Country: row.Country,
            State: row.State,
            City: row.City,
            PinCode: row.Pincode,
        }));

        // // Assuming file is an array of objects
        // const parsedSaveData = file.map((row, index) => ({

        //     categorytype_name: '',
        //     category_status: row.Vendor_Status,
        //     vendor_type: row.Vendor_Type,
        //     location: row.Vendor_Location,
        //     reference: row.Reference,
        //     company_name: row.Company_Name,
        //     contact_person_name: row.Contact_Person,
        //     designation: row.Designation,
        //     mobile_no: row.Mobile_Number,
        //     telephone_no: row.Tele_Number,
        //     web: row.Website,
        //     email: row.Email_ID,
        //     register_country: row.Country,
        //     register_state: row.State,
        //     register_city: row.City,
        //     register_pincode: row.Pincode,
        // }));

        // Assuming file is an array of objects
        const parsedSaveData = file.map((row, index) => ({

            categorytype_name: '',
            category_status: row.Status,
            vendor_type: row.Vendor_Type,
            location: row.Vendor_Location,
            reference: row.Reference,
            // company_name: row.Company_Name,
            // contact_person_name: row.Contact_Person,
            // designation: row.Designation,

            company_name: (row.Company_Name || '').toUpperCase(),
            contact_person_name: (row.Contact_Person || '').toUpperCase(),
            designation: (row.Designation || '').toUpperCase(),
            
            mobile_no: row.Mobile_Number,
            telephone_no: row.Tele_Number,
            // web: row.Website,
            // email: row.Email_ID,

            web: (row.Website || '').toLowerCase(),
            email: (row.Email_ID || '').toLowerCase(),

            register_country: row.Country,
            register_state: row.State,
            register_city: row.City,
            register_pincode: row.Pincode,
        }));
        
        setSaveData(parsedSaveData);
        setData(parsedData); // Store the parsed data in state

        setBulkUpload(true);
    };


    useEffect(() => {
        console.log("Initializing DataTable with data:", data);
        if (loading && data.length > 0) {
            if ($.fn.DataTable.isDataTable(tableRef.current)) {
                const dataTable = $(tableRef.current).DataTable();
                dataTable.clear().rows.add(data).draw();
            } else {
                $(tableRef.current).DataTable({
                    data: data,
                    colReorder: true,
                    layout: {
                        topStart: "search",
                        topEnd: "",
                    },
                    language: {
                        search: "",
                        searchPlaceholder: "Search...",
                    },
                    columns: [
                        { data: "SlNo", title: "Sl&nbsp;No", width: "15%" },
                        { data: "CompanyName", title: "Company&nbsp;Name", width: "15%" },
                        { data: "ContactPersonName", title: "Contact&nbsp;Person&nbsp;Name", width: "15%" },
                        { data: "Designation", title: "Designation", width: "10%", visible: false },
                        { data: "MobileNumber", title: "Mobile&nbsp;Number", width: "10%" },
                        { data: "TelephoneNumber", title: "Telephone&nbsp;Number", width: "10%", visible: false },
                        { data: "Website", title: "Website", width: "10%", visible: false },
                        { data: "EmailId", title: "Email&nbsp;Id", width: "10%" },
                        { data: "Country", title: "Country", width: "10%" },
                        { data: "State", title: "State", width: "10%" },
                        { data: "City", title: "City", width: "10%" },
                        { data: "PinCode", title: "Pincode", width: "10%", visible: false },
                        // {
                        //     data: null,
                        //     title: "Action",
                        //     width: "15%",
                        //     render: (data, type, row) => {
                        //         return `
                        //                         <button class="edit-btn" data-id="${row.SlNo}"><i class="material-icons">edit</i></button>
                        //                         <button class="delete-btn" data-id="${row.SlNo}"><i class="material-icons">delete</i></button>
                        //                     `;
                        //     },
                        // },
                    ],
                    rowCallback: function (row, data, index) {
                        const backgroundColor = index % 2 === 0 ? "#e6f2ff" : "#ffffff";
                        const hoverColor = "#80bfff";
                        $(row).css({
                            "background-color": backgroundColor,
                            transition: "background-color 0.3s ease",
                            cursor: "pointer",
                        });

                        $(row).hover(
                            function () {
                                $(this).css("background-color", hoverColor);
                            },
                            function () {
                                $(this).css("background-color", backgroundColor);
                            }
                        );
                    },
                    drawCallback: function () {
                        const currentData = this.api().rows().data().toArray();
                        $(".edit-btn").on("click", function () {
                            const id = $(this).data("id");
                            const item = currentData.find((d) => d.SlNo === id);
                            handleEdit(item);
                        });

                        $(".delete-btn").on("click", function () {
                            const id = $(this).data("id");
                            console.log("id is :", id);
                            const item = currentData.find((d) => d.SlNo === id);
                            console.log("item is :", item);
                            handleDelete(item);
                        });
                    },
                });
            }
            setLoading(false); // Set loading to false after the table is updated
        }
    }, [loading, data]);


    // const handleEdit = (item) => {
    //     console.log("Edit item is : ", item);

    //     setCompanyName(item?.CompanyName || '');
    //     setContactPersonName(selectedItem?.ContactPersonName || '');
    //     setDesignation(selectedItem?.Designation || '');
    //     setMobileNo(selectedItem?.MobileNumber || '');
    //     setTelephoneNo(selectedItem?.TelephoneNumber || '');
    //     setEmail(selectedItem?.EmailId || '');
    //     setWeb(selectedItem?.Website || '');
    // };

    const handleEdit = (item) => {
        console.log("Edit item is : ", item);

        setCompanyName(item?.CompanyName || '');
        setContactPersonName(item?.ContactPersonName || '');
        setDesignation(item?.Designation || '');
        setMobileNo(item?.MobileNumber || '');
        setTelephoneNo(item?.TelephoneNumber || '');
        setEmail(item?.EmailId || '');
        setWeb(item?.Website || '');
        setRegisterPincode(item?.PinCode || '');

        // setRegisterCountry(item?.Country);
    };



    const handleDelete = (item) => {
        console.log("Delete item is : ", item);
    };


    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth required error={!!errors.vendorStatus}>
                        <InputLabel id="vendorStatus-label">Vendor Status</InputLabel>
                        <Select
                            labelId="vendorStatus-label"
                            id="vendorStatus"
                            value={vendorStatus}
                            onChange={(e) => handleMainChange('vendorStatus', e.target.value)}
                            label="Vendor Status"
                            required
                        >
                            <MenuItem value="Select Vendor Status">Select Vendor Status</MenuItem>
                            <MenuItem value="Temp">Temp</MenuItem>
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Inactive">Inactive</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth required error={!!errors.vendorType}>
                        <InputLabel id="vendorType-label">Vendor Type</InputLabel>
                        <Select
                            labelId="vendorType-label"
                            id="vendorType"
                            value={vendorType}
                            onChange={(e) => handleMainChange('vendorType', e.target.value)}
                            label="Vendor Type"
                            required
                        >
                            <MenuItem value="Select Vendor Type">Select Vendor Type</MenuItem>
                            <MenuItem value="Carrier">Carrier</MenuItem>
                            <MenuItem value="Manufacturer">Manufacturer</MenuItem>
                            <MenuItem value="Wholesaler">Wholesaler</MenuItem>
                            <MenuItem value="Retailer">Retailer</MenuItem>
                            <MenuItem value="Trader">Trader</MenuItem>
                            <MenuItem value="ServiceProvider">Service Provider</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth required error={!!errors.vendorLocation}>
                        <InputLabel id="vendorLocation-label">Vendor Location</InputLabel>
                        <Select
                            labelId="vendorLocation-label"
                            id="vendorLocation"
                            value={vendorLocation}
                            onChange={(e) => handleMainChange('vendorLocation', e.target.value)}
                            label="Vendor Location"
                            required
                        >
                            <MenuItem value="Select Vendor Location">Select Vendor Location</MenuItem>
                            <MenuItem value="India">India</MenuItem>
                            <MenuItem value="USA">USA</MenuItem>
                            <MenuItem value="OverSeas">OverSeas</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        id="vendorReference"
                        label="Vendor Reference"
                        variant="outlined"
                        fullWidth
                        value={vendorReference}
                        onChange={(e) => handleMainChange('vendorReference', e.target.value)}
                    />
                </Grid>
            </Grid>

            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ marginTop: '50px', marginBottom: '20px' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon style={{ fontSize: '32px', color: '#cce0ff', backgroundColor: '#003380', width: '30px', height: '30px', borderRadius: '8px' }} />} aria-controls="panel1bh-content" id="panel1bh-header" style={{ backgroundColor: '#cce0ff' }} >
                    <Typography sx={{ width: '33%', flexShrink: 0 }} style={{ fontFamily: 'Times New Roman', fontSize: '18px', color: '#003380', fontWeight: 'bold' }}>
                        Customer&nbsp;Details
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {/* {vendorStatus === "Temp" ? (
                            <Grid item xs={12} sm={12}>
                                <div id="contactdetails" style={{ border: '1px solid #003380', padding: '20px', borderRadius: '5px', marginTop: '30px' }}>
                                    <Typography variant="h6" style={{ fontFamily: 'Times New Roman', fontSize: '18px', color: '#003380', fontWeight: 'bold', marginLeft: '30px' }}>
                                        Contact Details
                                    </Typography>
                                    <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="companyName"
                                                label="Company Name"
                                                variant="outlined"
                                                fullWidth
                                                value={companyName}
                                                onChange={(e) => handleUpdateChange("CompanyName", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="contactPersonName"
                                                label="Contact Person Name"
                                                variant="outlined"
                                                fullWidth
                                                value={contactPersonName}
                                                onChange={(e) => handleUpdateChange("ContactPersonName", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="designation"
                                                label="Designation"
                                                variant="outlined"
                                                fullWidth
                                                value={designation}
                                                onChange={(e) => handleUpdateChange("Designation", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="mobileNo"
                                                label="Mobile No"
                                                variant="outlined"
                                                fullWidth
                                                value={mobileNo}
                                                onChange={(e) => handleUpdateChange("MobileNo", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="telephoneNo"
                                                label="Telephone No"
                                                variant="outlined"
                                                fullWidth
                                                value={telephoneNo}
                                                onChange={(e) => handleUpdateChange("TelephoneNo", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="email"
                                                label="Email"
                                                variant="outlined"
                                                fullWidth
                                                value={email}
                                                onChange={(e) => handleUpdateChange("Email", e.target.value)}
                                                error={!!error}
                                                helperText={error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="web"
                                                label="Web"
                                                variant="outlined"
                                                fullWidth
                                                value={web}
                                                onChange={(e) => handleUpdateChange("Web", e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        ) : (
                            <Grid item xs={12} sm={6}>
                                <div id="contactdetails" style={{ border: '1px solid #003380', padding: '20px', borderRadius: '5px', marginTop: '30px' }}>
                                    <Typography variant="h6" style={{ fontFamily: 'Times New Roman', fontSize: '18px', color: '#003380', fontWeight: 'bold', marginLeft: '30px' }}>
                                        Contact Details
                                    </Typography>
                                    <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="companyName"
                                                label="Company Name"
                                                variant="outlined"
                                                fullWidth
                                                value={companyName}
                                                onChange={(e) => handleUpdateChange("CompanyName", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="contactPersonName"
                                                label="Contact Person Name"
                                                variant="outlined"
                                                fullWidth
                                                value={contactPersonName}
                                                onChange={(e) => handleUpdateChange("ContactPersonName", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="designation"
                                                label="Designation"
                                                variant="outlined"
                                                fullWidth
                                                value={designation}
                                                onChange={(e) => handleUpdateChange("Designation", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="mobileNo"
                                                label="Mobile No"
                                                variant="outlined"
                                                fullWidth
                                                value={mobileNo}
                                                onChange={(e) => handleUpdateChange("MobileNo", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="telephoneNo"
                                                label="Telephone No"
                                                variant="outlined"
                                                fullWidth
                                                value={telephoneNo}
                                                onChange={(e) => handleUpdateChange("TelephoneNo", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="email"
                                                label="Email"
                                                variant="outlined"
                                                fullWidth
                                                value={email}
                                                onChange={(e) => handleUpdateChange("Email", e.target.value)}
                                                error={!!error}
                                                helperText={error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="web"
                                                label="Web"
                                                variant="outlined"
                                                fullWidth
                                                value={web}
                                                onChange={(e) => handleUpdateChange("Web", e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        )} */}

                        {vendorStatus === "Temp" ? (
                            <>
                                <Grid item xs={12} sm={5}>
                                    <div id="contactdetails" style={{ border: '1px solid #003380', padding: '20px', borderRadius: '5px', marginTop: '30px' }}>
                                        <Typography variant="h6" style={{ fontFamily: 'Times New Roman', fontSize: '18px', color: '#003380', fontWeight: 'bold', marginLeft: '30px' }}>
                                            Bulk Upload
                                        </Typography>
                                        <Grid container spacing={2} style={{ marginTop: '35px', marginBottom: '40px' }}>
                                            <Grid
                                                container
                                                spacing={2}
                                                alignItems="center"
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    pointerEvents: (vendorStatus === "Temp" && selectedItem?.OrganizationDetailsId) ? "none" : "auto",
                                                    opacity: (vendorStatus === "Temp" && selectedItem?.OrganizationDetailsId) ? 0.5 : 1,
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                >
                                                    <Box display="flex" flexDirection="column" alignItems="center">
                                                        <VendorExcelUpload onFileUpload={handleFileUpload} />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={7}>
                                    <div id="contactdetails" style={{ border: '1px solid #003380', padding: '20px', borderRadius: '5px', marginTop: '30px' }}>
                                        <Typography variant="h6" style={{ fontFamily: 'Times New Roman', fontSize: '18px', color: '#003380', fontWeight: 'bold', marginLeft: '30px' }}>
                                            Contact Details
                                        </Typography>
                                        <Grid container spacing={2} style={{ marginTop: '10px', marginBottom: '10px' }}>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="companyName"
                                                    label="Company Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={companyName}
                                                    onChange={(e) => handleUpdateChange("CompanyName", e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    id="designation"
                                                    label="Designation"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={designation}
                                                    onChange={(e) => handleUpdateChange("Designation", e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    id="contactPersonName"
                                                    label="Contact Person Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={contactPersonName}
                                                    onChange={(e) => handleUpdateChange("ContactPersonName", e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    id="mobileNo"
                                                    label="Mobile No"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={mobileNo}
                                                    onChange={(e) => handleUpdateChange("MobileNo", e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    id="telephoneNo"
                                                    label="Telephone No"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={telephoneNo}
                                                    onChange={(e) => handleUpdateChange("TelephoneNo", e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    id="web"
                                                    label="Web"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={web}
                                                    onChange={(e) => handleUpdateChange("Web", e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    id="email"
                                                    label="Email"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={email}
                                                    onChange={(e) => handleUpdateChange("Email", e.target.value)}
                                                    error={!!error}
                                                    helperText={error}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    id="registerPincode"
                                                    label="PinCode"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={registerPincode}
                                                    onChange={(e) => setRegisterPincode(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Autocomplete
                                                    options={countryData}
                                                    getOptionLabel={(option) => option.CountryName || ''}
                                                    value={registerCountry}
                                                    // onChange={(e, value) => setRegisterCountry(value)}
                                                    isOptionEqualToValue={(option, value) => option.CountryId === value.CountryId}
                                                    onChange={handleCountryChange}
                                                    renderInput={(params) => <TextField {...params} label="Country" />}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Autocomplete
                                                    // disabled={stateFieldDisabled}
                                                    disabled={!registerCountry} // Disable state until a country is selected
                                                    options={filteredStateData}
                                                    getOptionLabel={(option) => option.StateName || ''}
                                                    value={registerState}
                                                    // onChange={(e, value) => setRegisterState(value)}
                                                    isOptionEqualToValue={(option, value) => option.StateId === value.StateId}
                                                    onChange={handleStateChange}
                                                    renderInput={(params) => <TextField {...params} label="State" />}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Autocomplete
                                                    // disabled={cityFieldDisabled}
                                                    disabled={!registerState} // Disable city until a state is selected
                                                    options={filteredCityData}
                                                    getOptionLabel={(option) => option.CityName || ''}
                                                    value={registerCity}
                                                    // onChange={(e, value) => setRegisterCity(value)}
                                                    isOptionEqualToValue={(option, value) => option.CityId === value.CityId}
                                                    onChange={handleCityChange}
                                                    renderInput={(params) => <TextField {...params} label="City" />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                {data.length > 0 && (
                                    <Grid item xs={12} sm={12}>
                                        <div style={{ marginTop: "20px", maxWidth: isMobile ? "340px" : "100%", overflowX: "auto", }} >
                                            <table ref={tableRef} className="display" style={{ width: "100%", border: "1px solid #003380" }} >
                                                <thead style={{ backgroundColor: "#00004d", color: "white" }}>
                                                    <tr>
                                                        <th>Sl&nbsp;No</th>
                                                        <th>Company&nbsp;Name</th>
                                                        <th>Contact&nbsp;Person</th>
                                                        <th>Designation</th>
                                                        <th>Mobile&nbsp;Number</th>
                                                        <th>Telephone&nbsp;Number</th>
                                                        <th>Website</th>
                                                        <th>EmailId</th>
                                                        <th>Country</th>
                                                        <th>State</th>
                                                        <th>City</th>
                                                        <th>Pincode</th>
                                                        {/* <th>Action</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody></tbody>
                                            </table>
                                        </div>
                                    </Grid>
                                )}
                            </>
                        ) : (
                            <Grid item xs={12} sm={12}>
                                <div id="contactdetails" style={{ border: '1px solid #003380', padding: '20px', borderRadius: '5px', marginTop: '30px' }}>
                                    <Typography variant="h6" style={{ fontFamily: 'Times New Roman', fontSize: '18px', color: '#003380', fontWeight: 'bold', marginLeft: '30px' }}>
                                        Contact Details
                                    </Typography>
                                    <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="companyName"
                                                label="Company Name"
                                                variant="outlined"
                                                fullWidth
                                                value={companyName}
                                                onChange={(e) => handleUpdateChange("CompanyName", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="contactPersonName"
                                                label="Contact Person Name"
                                                variant="outlined"
                                                fullWidth
                                                value={contactPersonName}
                                                onChange={(e) => handleUpdateChange("ContactPersonName", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="designation"
                                                label="Designation"
                                                variant="outlined"
                                                fullWidth
                                                value={designation}
                                                onChange={(e) => handleUpdateChange("Designation", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="mobileNo"
                                                label="Mobile No"
                                                variant="outlined"
                                                fullWidth
                                                value={mobileNo}
                                                onChange={(e) => handleUpdateChange("MobileNo", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="telephoneNo"
                                                label="Telephone No"
                                                variant="outlined"
                                                fullWidth
                                                value={telephoneNo}
                                                onChange={(e) => handleUpdateChange("TelephoneNo", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="email"
                                                label="Email"
                                                variant="outlined"
                                                fullWidth
                                                value={email}
                                                onChange={(e) => handleUpdateChange("Email", e.target.value)}
                                                error={!!error}
                                                helperText={error}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="web"
                                                label="Web"
                                                variant="outlined"
                                                fullWidth
                                                value={web}
                                                onChange={(e) => handleUpdateChange("Web", e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        )}

                        {vendorStatus !== "Temp" && (
                            <Grid item xs={12} sm={6}>
                                <div id='contactdetails' style={{ border: '1px solid #003380', padding: '20px', borderRadius: '5px', marginTop: '30px' }}>
                                    <Typography variant="h6" style={{ fontFamily: 'Times New Roman', fontSize: '18px', color: '#003380', fontWeight: 'bold', marginLeft: '30px' }}>Bank Details</Typography>
                                    <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="bankName"
                                                label="Bank Name"
                                                variant="outlined"
                                                fullWidth
                                                value={bankName}
                                                onChange={(e) => handleUpdateChange('BankName', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="accountNumber"
                                                label="Account No"
                                                variant="outlined"
                                                fullWidth
                                                value={accountNumber}
                                                onChange={(e) => handleUpdateChange('AccountNumber', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="accountType"
                                                label="Account Type"
                                                variant="outlined"
                                                fullWidth
                                                value={accountType}
                                                onChange={(e) => handleUpdateChange('AccountType', e.target.value)}
                                            />
                                        </Grid>
                                        {vendorLocation === "India" && (
                                            <Grid item xs={6}>
                                                <TextField
                                                    id="ifscCode"
                                                    label="IFSC Code"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={ifscCode}
                                                    onChange={(e) => handleUpdateChange('IFSCCode', e.target.value)}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </div>
                            </Grid>
                        )}

                        {vendorStatus !== 'Temp' && (
                            <Grid item xs={12} sm={6}>
                                <div style={{ border: '1px solid #003380', padding: '20px', borderRadius: '5px', marginTop: '30px' }}>
                                    <Grid container alignItems="center" justifyContent="space-between">
                                        <Typography variant="h6" style={{ fontFamily: 'Times New Roman', fontSize: '18px', color: '#003380', fontWeight: 'bold' }}>
                                            Register Address
                                        </Typography>
                                        <FormControlLabel
                                            control={<Checkbox checked={copyAddress} onChange={handleCopyAddress} />}
                                            label="Same as Communication Address"
                                        />
                                    </Grid>
                                    <Grid container spacing={2} style={{ marginTop: '10px', marginBottom: '20px' }}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="registerAddress"
                                                label="Address"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                value={registerAddress}
                                                onChange={(e) => setRegisterAddress(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Autocomplete
                                                options={countryData}
                                                getOptionLabel={(option) => option.CountryName || ''}
                                                value={registerCountry}
                                                // onChange={(e, value) => setRegisterCountry(value)}
                                                isOptionEqualToValue={(option, value) => option.CountryId === value.CountryId}
                                                onChange={handleCountryChange}
                                                renderInput={(params) => <TextField {...params} label="Country" />}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Autocomplete
                                                // disabled={stateFieldDisabled}
                                                disabled={!registerCountry} // Disable state until a country is selected
                                                options={filteredStateData}
                                                getOptionLabel={(option) => option.StateName || ''}
                                                value={registerState}
                                                // onChange={(e, value) => setRegisterState(value)}
                                                isOptionEqualToValue={(option, value) => option.StateId === value.StateId}
                                                onChange={handleStateChange}
                                                renderInput={(params) => <TextField {...params} label="State" />}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Autocomplete
                                                // disabled={cityFieldDisabled}
                                                disabled={!registerState} // Disable city until a state is selected
                                                options={filteredCityData}
                                                getOptionLabel={(option) => option.CityName || ''}
                                                value={registerCity}
                                                // onChange={(e, value) => setRegisterCity(value)}
                                                isOptionEqualToValue={(option, value) => option.CityId === value.CityId}
                                                onChange={handleCityChange}
                                                renderInput={(params) => <TextField {...params} label="City" />}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="registerPincode"
                                                label="PinCode"
                                                variant="outlined"
                                                fullWidth
                                                value={registerPincode}
                                                onChange={(e) => setRegisterPincode(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        )}

                        {vendorStatus !== 'Temp' && (
                            <Grid item xs={12} sm={6}>
                                <div style={{ border: '1px solid #003380', padding: '20px', borderRadius: '5px', marginTop: '30px' }}>
                                    <Typography variant="h6" style={{ fontFamily: 'Times New Roman', fontSize: '18px', color: '#003380', fontWeight: 'bold' }}>
                                        Communication Address
                                    </Typography>
                                    <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="communicationAddress"
                                                label="Address"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                value={communicationAddress}
                                                onChange={(e) => setCommunicationAddress(e.target.value)}
                                                disabled={copyAddress}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Autocomplete
                                                options={communicationCountryData}
                                                getOptionLabel={(option) => option.CountryName || ''}
                                                value={communicationCountry}
                                                // onChange={(e, value) => setCommunicationCountry(value)}
                                                isOptionEqualToValue={(option, value) => option.CountryId === value.CountryId}
                                                onChange={handleCommunicationCountryChange}
                                                renderInput={(params) => <TextField {...params} label="Country" />}
                                                disabled={copyAddress}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Autocomplete
                                                disabled={!communicationCountry || copyAddress}
                                                options={filteredCommunicationStateData}
                                                getOptionLabel={(option) => option.StateName || ''}
                                                value={communicationState}
                                                // onChange={(e, value) => setCommunicationState(value)}
                                                isOptionEqualToValue={(option, value) => option.StateId === value.StateId}
                                                onChange={handleCommunicationStateChange}
                                                renderInput={(params) => <TextField {...params} label="State" />}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Autocomplete
                                                disabled={!communicationState || copyAddress}
                                                options={filteredCommunicationCityData}
                                                getOptionLabel={(option) => option.CityName || ''}
                                                value={communicationCity}
                                                // onChange={(e, value) => setCommunicationCity(value)}
                                                isOptionEqualToValue={(option, value) => option.CityId === value.CityId}
                                                onChange={handleCommunicationCityChange}
                                                renderInput={(params) => <TextField {...params} label="City" />}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="communicationPincode"
                                                label="PinCode"
                                                variant="outlined"
                                                fullWidth
                                                value={communicationPincode}
                                                onChange={(e) => setCommunicationPincode(e.target.value)}
                                                disabled={copyAddress}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        )}

                        {vendorStatus !== "Temp" && vendorLocation === "India" && (
                            <Grid item xs={12} sm={6}>
                                <div id='contactdetails' style={{ border: '1px solid #003380', padding: '20px', borderRadius: '5px', marginTop: '30px' }}>
                                    <Typography variant="h6" style={{ fontFamily: 'Times New Roman', fontSize: '18px', color: '#003380', fontWeight: 'bold', marginLeft: '30px' }}>KYC Documents</Typography>
                                    <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="kycDocuments"
                                                label="KYC Documents"
                                                variant="outlined"
                                                fullWidth
                                                value={kycDocuments}
                                                onChange={(e) => handleUpdateChange('KYCDocuments', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="panNo"
                                                label="Pan No"
                                                variant="outlined"
                                                fullWidth
                                                value={panNo}
                                                onChange={(e) => handleUpdateChange('PanNo', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="gstNo"
                                                label="GST No"
                                                variant="outlined"
                                                fullWidth
                                                value={gstNo}
                                                onChange={(e) => handleUpdateChange('GSTNo', e.target.value)}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                            <TextField
                                                id="iecNo"
                                                label="IEC No"
                                                variant="outlined"
                                                fullWidth
                                                value={iecNo}
                                                onChange={(e) => handleUpdateChange('IECNo', e.target.value)}
                                            />
                                        </Grid> */}
                                    </Grid>
                                </div>
                            </Grid>
                        )}

                        {vendorStatus !== "Temp" && (
                            <Grid item xs={12} sm={6}>
                                <div id='contactdetails' style={{ border: '1px solid #003380', padding: '20px', borderRadius: '5px', marginTop: '30px' }}>
                                    <Typography variant="h6" style={{ fontFamily: 'Times New Roman', fontSize: '18px', color: '#003380', fontWeight: 'bold', marginLeft: '30px' }}>Credit Details</Typography>
                                    <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="creditDays"
                                                label="Credit Days"
                                                variant="outlined"
                                                fullWidth
                                                value={creditDays}
                                                onChange={(e) => handleUpdateChange('CreditDays', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="creditLimit"
                                                label="Credit Limit"
                                                variant="outlined"
                                                fullWidth
                                                value={creditLimit}
                                                onChange={(e) => handleUpdateChange('CreditLimit', e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        )}

                        {vendorStatus !== "Temp" && (
                            <Grid item xs={12} sm={6}>
                                <div id='contactdetails' style={{ border: '1px solid #003380', padding: '20px', borderRadius: '5px', marginTop: '30px' }}>
                                    <Typography variant="h6" style={{ fontFamily: 'Times New Roman', fontSize: '18px', color: '#003380', fontWeight: 'bold', marginLeft: '30px' }}>Branch Details</Typography>
                                    <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="branchType"
                                                label="Branch Type"
                                                variant="outlined"
                                                fullWidth
                                                value={branchType}
                                                onChange={(e) => handleUpdateChange('BranchType', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="branchName"
                                                label="Branch Name"
                                                variant="outlined"
                                                fullWidth
                                                value={branchName}
                                                onChange={(e) => handleUpdateChange('BranchName', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="branchAddress"
                                                label="Address"
                                                variant="outlined"
                                                fullWidth
                                                value={branchAddress}
                                                onChange={(e) => handleUpdateChange('BranchAddress', e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Box display="flex" justifyContent="center" alignItems="center" gap="10px" style={{ marginTop: "80px", marginBottom: "30px" }} >
                <Button variant="contained" color="primary" onClick={handleSave}>
                    Save
                    <SaveIcon sx={{ fontSize: "15px", marginLeft: "5px", marginTop: "-1px" }} />
                </Button>
                <Button variant="contained" color="primary" onClick={onClose}>
                    Close
                    <CloseIcon sx={{ fontSize: "17px", marginLeft: "5px", marginTop: "-1px" }} />
                </Button>
            </Box>

            <Dialog open={openDialog} onClose={handleCancelled} ref={dialogRef} style={{ zIndex: 1300, backdropFilter: "blur(5px)" }} disableBackdropClick disableEscapeKeyDown>
                <DialogTitle>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={SuccessIcon} alt="Success Icon" style={{ marginRight: '10px', width: '200px', height: '100px', marginLeft: '50px' }} />
                    </span>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        {"Organization Data Saved Successfully!"}
                    </Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button onClick={handleCancelled} color="primary" variant="contained" autoFocus sx={{ py: 0.4 }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={failureDialog} onClose={handleFailureClose} ref={failureRef} style={{ zIndex: 1300, backdropFilter: "blur(5px)" }} >
                <DialogTitle>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={ErrorIcon} alt="Success Icon" style={{ marginRight: '10px', width: '200px', height: '100px', marginLeft: '20px' }} />
                    </span>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        {/* {"Organization Data Saved Failure!"} */}
                        {errorMessage || "Organization Data Saved Failure!"}
                    </Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button onClick={handleFailureClose} color="primary" variant="contained" autoFocus sx={{ py: 0.4 }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default Vendor;
