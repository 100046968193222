import React, { Suspense, useCallback, useEffect, useState } from "react";
import {
  Typography,
  Box,
  Divider,
  Button,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import { apiUrl } from "../../apiConfig";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
const CityExcelUpload = React.lazy(() =>
  import("../../Components/ExcelUpload/CityExcelUpload")
);

const CityDetails = ({ onClose, item }) => {
  const initialSelectedItem = {
    CityName: "",
    StateName: "",
    StateCode: "",
    CountryCode: "",
    CountryName: "",
  };

  const [selectedItem, setSelectedItem] = useState(item || initialSelectedItem);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false); // State for error dialog
  const [existingDialogOpen, setExistingDialogOpen] = useState(false);
  // const [countrydata, setcountrydata] = useState([]);
  // const [statedata, setstatedata] = useState([]);
  const [errorFields, setErrorFields] = useState([]); // State to track fields with errors
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [stateField, setStateField] = useState(true);
  const [filteredState, setFilteredState] = useState([]);

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      console.log("Selected Item:", selectedItem);

      const apiType = selectedItem && selectedItem.CityId ? "Update" : "Post";

      // // Debugging logs for selectedItem.State_id and stateData
      console.log("Selected State ID (StateCode):", selectedItem.State_id);
      console.log("State Data:", stateData);

      const stateId =
        stateData.find(
          (sm) =>
            sm.StateCode === selectedItem.State_id &&
            sm.StateName === selectedItem.StateName
        )?.StateId || "";

      console.log("Resolved State ID:", stateId);

      const requestData = {
        apitype: apiType,
        city_id: selectedItem?.CityId || "",
        city_name: selectedItem.CityName || "",
        state_id: selectedItem.State_id || "",
        state_name: selectedItem.StateName || "",
        country_id:
          countryData.find((cm) => cm.ISO2Code === selectedItem.Country_id)
            ?.CountryId || "",
        country_name: selectedItem.CountryName || "",
      };

      console.log("Request Data:", requestData); // Log the request data for debugging

      const response = await axios.post(`${apiUrl}/citymaster/`, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log("Response:", response.data);
      if (response.data.success) {
        setSuccessDialogOpen(true); // Show success dialog on successful save
      } else {
        console.error("Error response data:", response.data);
        setErrorDialogOpen(true); // Show error dialog on unsuccessful response
      }
    } catch (error) {
      console.error("Error saving data:", error);
      if (error.response) {
        console.error("Error response:", error.response.data);
      } else if (error.request) {
        console.error("Error request:", error.request);
      } else {
        console.error("General error:", error.message);
      }
      setErrorDialogOpen(true); // Show error dialog on exception
    }
  };

  useEffect(() => {
    console.log(selectedItem);
  }, [selectedItem]);
  const handleChange = (field, value) => {
    setSelectedItem({ ...selectedItem, [field]: value });

    if (errorFields.includes(field)) {
      setErrorFields((prev) => prev.filter((f) => f !== field));
    }
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
    setSelectedItem(initialSelectedItem);
    onClose(); // Close the details dialog if needed
  };

  const handleExistingDialogClose = () => {
    setExistingDialogOpen(false);
  };

  const handleclosedetails = () => {
    onClose(false);
  };

  const checkExistingData = async (countryName) => {
    try {
      // Replace with your actual API call to check if countryName exists in your DataTable
      const response = await axios.get(
        `${apiUrl}/countrymaster/exists/${countryName}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      console.log("Existing Data Check Response:", response.data);

      return response.data.exists;
    } catch (error) {
      console.error("Error checking existing data:", error.message);
      return false;
    }
  };
  const handleClose = () => {
    console.log("Closing details dialog");
    setSuccessDialogOpen(false);
    onClose();
  };
  const handleCloseDialog = () => {
    console.log("Closing details dialog");
    setErrorDialogOpen(false);
    onClose();
  };
  const fetchDataState = useCallback(async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/statemaster/`,
        { apitype: "Get" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const responseData = response.data;
      setStateData(responseData.data);
    } catch (error) {
      console.log("Error fetching data: " + error.message);
    }
  }, [accessToken]);

  const fetchDataCountry = useCallback(async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/countrymaster/`,
        { apitype: "Get" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const responseData = response.data;
      setCountryData(responseData.data);
    } catch (error) {
      console.log("Error fetching data: " + error.message);
    }
  }, [accessToken]);

  useEffect(() => {
    fetchDataState();
    fetchDataCountry();
  }, [fetchDataCountry, fetchDataState]);
  const handleCountryChange = (event) => {
    const value = event.target.value;
    const countryName =
      countryData.find((cm) => cm.ISO2Code === value)?.CountryName || "";
    setSelectedItem((prevSelectedItem) => ({
      ...prevSelectedItem,
      CountryName: countryName,
      Country_id: value,
    }));
    const countryId =
      countryData.find((cm) => cm.ISO2Code === value)?.CountryId || "";
    console.log(countryId);
    updateFilteredState(countryId);
    setStateField(false);
  };

  // Handle change when selecting a country by name
  const handleCountryChangeInvert = (event) => {
    const value = event.target.value;
    const isoCode =
      countryData.find((cm) => cm.CountryName === value)?.ISO2Code || "";
    setSelectedItem((prevSelectedItem) => ({
      ...prevSelectedItem,
      CountryName: value,
      Country_id: isoCode,
    }));
    const countryId =
      countryData.find((cm) => cm.CountryName === value)?.CountryId || "";
    updateFilteredState(countryId);
    setStateField(false);
  };

  // Function to update filteredState based on selected countryId
  const updateFilteredState = useCallback(
    (countryId) => {
      const filteredStates = stateData.filter(
        (state) => state.Country_id === countryId
      );
      setFilteredState(filteredStates);
    },
    [stateData]
  );
  const handleStateChange = (event, field) => {
    const value = event.target.value;

    if (field === "State_id") {
      const stateName =
        filteredState.find((state) => state.StateId === value)?.StateName || "";
      setSelectedItem((prevSelectedItem) => ({
        ...prevSelectedItem,
        State_id: value,
        StateName: stateName,
      }));
    } else if (field === "StateName") {
      const stateId =
        filteredState.find((state) => state.StateName === value)?.StateId || "";
      setSelectedItem((prevSelectedItem) => ({
        ...prevSelectedItem,
        State_id: stateId,
        StateName: value,
      }));
    }
  };
  useEffect(() => {
    console.log(item);
    if (item && item.CityId) {
      updateFilteredState(item.Country_id);
      setStateField(false);
    }
  }, [item, updateFilteredState]);
  return (
    <div
      style={{
        width: "90%",
        backgroundColor: "white",
        padding: "20px",
        borderRadius: isMobile ? "0" : "15px",
        minHeight: "500px",
        marginTop: "120px",
        marginLeft: isMobile ? "3px" : "65px",
        marginRight: isMobile ? "3px" : "50px",
        marginBottom: "50px",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <Typography
          variant="h4"
          component="h2"
          style={{
            fontFamily: "Times New Roman",
            fontSize: "22px",
            color: "#003380",
            fontWeight: "bold",
          }}
        >
          City&nbsp;Details
        </Typography>
      </Box>
      <Divider />
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{ marginTop: "55px" }}
      >
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="h4"
              component="h2"
              style={{
                fontFamily: "Times New Roman",
                fontSize: "18px",
                color: "#003380",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Browse&nbsp;File
            </Typography>
            <Suspense fallback={<div>Loading...</div>}>
              <CityExcelUpload onChange={handleclosedetails} />
            </Suspense>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          {!isMobile && (
            <Divider
              orientation="vertical"
              flexItem
              style={{
                marginTop: "-90px",
                backgroundColor: "#003380",
                width: "2px",
                height: "100%",
                minHeight: "220px",
                position: "absolute",
                top: "0",
                borderRadius: "2px",
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2} style={{ marginTop: "30px" }} >
            {/* First row with two text fields */}
            <Grid item xs={12} sm={12}>
              <TextField
                label="City Name"
                variant="outlined"
                size="small"
                fullWidth
                value={selectedItem?.CityName || ""}
                onChange={(e) => handleChange("CityName", e.target.value)}
                inputProps={{ maxLength: 50 }}
                error={errorFields.includes("CityName")}
                helperText={errorFields.includes("CityName") ? "Required" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="country-code-label">CountryCode</InputLabel>
                <Select
                  labelId="country-code-label"
                  id="country-code-select"
                  value={
                    countryData.find(
                      (cm) => cm.CountryId === selectedItem?.Country_id
                    )?.ISO2Code ||
                    selectedItem.Country_id ||
                    ""
                  }
                  onBlur={(event, value) => handleCountryChange(event, value)}
                  onChange={(event, value) => {
                    handleChange("Country_id", event.target.value);
                  }}
                  label="CountryCode"
                >
                  {countryData.map((option) => (
                    <MenuItem key={option.CountryId} value={option.ISO2Code}>
                      {option.ISO2Code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="country-name-label">CountryName</InputLabel>
                <Select
                  labelId="country-name-label"
                  id="country-name-select"
                  value={selectedItem?.CountryName || ""}
                  onBlur={(event, value) =>
                    handleCountryChangeInvert(event, value)
                  }
                  onChange={(event, value) => {
                    handleChange("CountryName", event.target.value);
                    handleCountryChangeInvert(event, value);
                  }}
                  label="CountryName"
                >
                  {countryData.map((option) => (
                    <MenuItem key={option.CountryId} value={option.CountryName}>
                      {option.CountryName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="state-id-label">State Code</InputLabel>
                <Select
                  disabled={stateField}
                  labelId="state-id-label"
                  id="state-id-select"
                  value={
                    selectedItem?.State_id || selectedItem?.StateCode || ""
                  }
                  onChange={(event) => handleStateChange(event, "State_id")}
                  label="State Code"
                >
                  {filteredState.map((option) => (
                    <MenuItem key={option.StateId} value={option.StateId}>
                      {option.StateCode}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="state-name-label">State Name</InputLabel>
                <Select
                  disabled={stateField}
                  labelId="state-name-label"
                  id="state-name-select"
                  value={selectedItem?.StateName || ""}
                  onChange={(event) => handleStateChange(event, "StateName")}
                  label="State Name"
                >
                  {filteredState.map((option) => (
                    <MenuItem key={option.StateId} value={option.StateName}>
                      {option.StateName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                style={{marginTop: "10px"}}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                >
                  Save
                </Button>
                <Button variant="contained" color="primary" onClick={onClose}>
                  Close
                </Button>
              </Box>
            </Grid>

            {/* Second row with two select dropdowns */}
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={successDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Success</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {item
              ? "Record Updated Successfully!"
              : "New Record Added Successfully!"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Error Dialog */}
      <Dialog
        open={errorDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Issue in saving</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please correct the data and try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Existing Country Dialog */}
      <Dialog
        open={existingDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Issue in saving</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please correct the data and try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CityDetails;
