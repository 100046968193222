import React, { Suspense, useCallback, useEffect, useRef, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  Box,
  Badge,
  Divider,
} from "@mui/material";
import LOGO from "../assets/images/Logo_8way.png";
import LOGO2 from "../assets/images/Logo_8way.png";
import { useTheme } from "@emotion/react";
import SideBar from "./SideBar";
import MenuIcon from "@mui/icons-material/Menu";
import DashBoard from "../Pages/DashBoard";
import { useNavigate } from "react-router-dom";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import PageNotFount from "../Pages/PageNotFount";
import ProfileUpdate from "../Pages/Settings/ProfileUpdate";
import AuditTrail from "../Pages/Report/AuditTrail";
import UserRegistration from "../Pages/Settings/UserRegistration";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import axios from "axios";
import { apiUrl } from "../apiConfig";
import { useSelector } from "react-redux";
import Country from "../Pages/Master/Country";
import State from "../Pages/Master/State";
import City from "../Pages/Master/City";
import SampleForm from "../Pages/Master/SampleCountryStateCity";
import Organization from "../Pages/Organization/Organization";
import Email from "../Pages/Email/Email";

import { lazy } from 'react';
import Preloader from "../Components/Preloader";
const CityMaster = lazy(() => import('../Pages/Master/City'));


function stringToColor(string) {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function stringAvatar(name) {
  const nameParts = name.split(" ");
  let avatarName;
  if (nameParts.length > 1) {
    avatarName = `${nameParts[0][0]}${nameParts[1][0]}`;
  } else {
    avatarName = `${nameParts[0][0]}`;
  }

  return {
    children: avatarName.toUpperCase(),
  };
}

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [anchorE3, setAnchorE3] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [contentType, setContentType] = useState("Dashboard");
  const navigate = useNavigate();
  const [loginDateTime] = useState(new Date());
  const inactivityTimeout = useRef(null);
  const sessionTimeout = 30 * 60 * 1000;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dialogRef = useRef(null);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const refreshToken = useSelector((state) => state.auth.refreshToken);
  const user = useSelector((state) => state.user.user);


  const resetTimer = useCallback(() => {
    if (inactivityTimeout.current) {
      clearTimeout(inactivityTimeout.current);
    }
    inactivityTimeout.current = setTimeout(() => {
      setIsDialogOpen(true);
    }, sessionTimeout);
  }, [sessionTimeout]);

  const handleActivity = useCallback(() => {
    resetTimer();
  }, [resetTimer]);

  useEffect(() => {
    resetTimer();
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keypress", handleActivity);

    return () => {
      if (inactivityTimeout.current) {
        clearTimeout(inactivityTimeout.current);
      }
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keypress", handleActivity);
    };
  }, [handleActivity, resetTimer]);

  const handleSessionExpire = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/logout/`,
        {
          refresh: refreshToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.detail) {
        throw new Error("Failed to delete data");
      }
      setIsDialogOpen(false);
      navigate("/");
    } catch (e) { }
  };

  const formatDate = (date) => {
    const options = { month: "short", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    const year = date.getFullYear();
    const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);
    return `${formattedDate} ${year} ${formattedTime}`;
  };

  const handleDrawerToggle = () => {
    setDrawerOpen((prev) => !prev);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuOpenMessage = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleMenuOpenNotification = (event) => {
    setAnchorE3(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleCloseMessage = () => {
    setAnchorE2(null);
  };
  const handleCloseNotification = () => {
    setAnchorE3(null);
  };
  const handleContentType = (type) => {
    setContentType(type);
  };
  const handlelogout = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/logout/`,
        {
          refresh: refreshToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.detail) {
        throw new Error("Failed to delete data");
      }
      navigate("/");
    } catch (e) { }
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: "rgb(255, 255, 255)",
            color: "black",
            boxShadow: "none",
            minHeight: "80px",
            zIndex: 1201,
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <div>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginRight: { xs: "20px", md: "40px" },
                }}
              >
                {isMobile ? (
                  <img
                    src={LOGO2}
                    alt="Logo"
                    style={{ height: "45px", verticalAlign: "middle" }}
                  />
                ) : (
                  <>
                    <img
                      src={LOGO}
                      alt="Logo"
                      style={{ height: "60px", verticalAlign: "middle" }}
                    />
                    <span style={{ verticalAlign: 'middle', display: 'inline-block', fontFamily: 'Times New Roman, Times, serif', fontWeight: 'bold', color: '#003380' }}>8&nbsp;WAYS&nbsp;GROUP</span>
                  </>
                )}
              </Typography>
            </div>

            <div style={{ marginRight: "auto" }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </div>
            {/* {!isMobile && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "25px",
                }}
              >
                <IconButton
                  aria-label="messages"
                  sx={{ marginRight: "25px" }}
                  onClick={handleMenuOpenMessage}
                >
                  <Badge badgeContent={4} color="primary">
                    <MailIcon color="action" />
                  </Badge>
                </IconButton>
                <Menu
                  id="messages"
                  anchorEl={anchorE2}
                  open={Boolean(anchorE2)}
                  onClose={handleCloseMessage}
                  MenuListProps={{
                    "aria-labelledby": "messages",
                  }}
                  sx={{
                    "& .MuiMenu-paper": {
                      width: "300px",
                      maxHeight: "500px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Times New Roman, Times, serif",
                        fontSize: isMobile ? "14px" : "14px",
                        opacity: "0.5",
                        fontWeight: "bold",
                        color: "black",
                        marginRight: "10px",
                      }}
                    >
                      Message
                    </Typography>
                    <Divider
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />
                  </Box>
                  <MenuItem></MenuItem>
                  <MenuItem></MenuItem>
                  <MenuItem></MenuItem>
                </Menu>
                <IconButton
                  aria-label="notifications"
                  onClick={handleMenuOpenNotification}
                >
                  <Badge badgeContent={4} color="primary">
                    <NotificationsIcon color="action" />
                  </Badge>
                </IconButton>
                <Menu
                  id="notifications"
                  anchorEl={anchorE3}
                  open={Boolean(anchorE3)}
                  onClose={handleCloseNotification}
                  MenuListProps={{
                    "aria-labelledby": "notifications",
                  }}
                  sx={{
                    "& .MuiMenu-paper": {
                      width: "300px",
                      maxHeight: "500px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Times New Roman, Times, serif",
                        fontSize: isMobile ? "14px" : "14px",
                        opacity: "0.5",
                        fontWeight: "bold",
                        color: "black",
                        marginRight: "10px",
                      }}
                    >
                      Notification
                    </Typography>
                    <Divider
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />
                  </Box>
                  <MenuItem></MenuItem>
                  <MenuItem></MenuItem>
                  <MenuItem></MenuItem>
                </Menu>
              </Box>
            )} */}
            {!isMobile && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Times New Roman, Times, serif",
                    fontSize: isMobile ? "15px" : "14px",
                    opacity: "0.5",
                    fontWeight: "bold",
                    color: "black",
                    marginRight: "20px",
                  }}
                >
                  {user.email}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Times New Roman, Times, serif",
                    fontSize: isMobile ? "15px" : "13px",
                    opacity: "0.5",
                    fontWeight: "bold",
                    color: "black",
                    marginRight: "20px",
                  }}
                >
                  {formatDate(loginDateTime)}
                </Typography>
              </Box>
            )}
            <div>
              <IconButton
                aria-label="user-account"
                aria-controls="user-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
              >
                <Avatar style={{ backgroundColor: stringToColor(`${user.first_name} ${user.last_name}`) }} {...stringAvatar(`${user.first_name} ${user.last_name}`)} ></Avatar>

              </IconButton>
              <Menu
                id="user-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                  "& .MuiMenu-paper": {
                    width: "200px",
                    maxHeight: "500px",
                  },
                }}
              >
                {isMobile && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Times New Roman, Times, serif",
                        fontSize: isMobile ? "14px" : "14px",
                        opacity: "0.5",
                        fontWeight: "bold",
                        color: "black",
                        marginRight: "10px",
                      }}
                    >
                      Admin
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Times New Roman, Times, serif",
                        fontSize: isMobile ? "12px" : "13px",
                        opacity: "0.5",
                        fontWeight: "bold",
                        color: "black",
                        marginRight: "10px",
                      }}
                    >
                      {formatDate(loginDateTime)}
                    </Typography>
                    <Divider
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />
                  </Box>
                )}
                {isMobile && (
                  <MenuItem onClick={handleMenuClose}>
                    <MailIcon color="action" sx={{ marginRight: "10px" }} />{" "}
                    Message
                  </MenuItem>
                )}
                {isMobile && (
                  <MenuItem onClick={handleMenuClose}>
                    <NotificationsIcon
                      color="action"
                      sx={{ marginRight: "10px" }}
                    />{" "}
                    Notification
                  </MenuItem>
                )}
                <MenuItem onClick={handlelogout}>
                  <LogoutIcon color="action" sx={{ marginRight: "10px" }} />
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <SideBar
          open={drawerOpen}
          toggleDrawer={handleDrawerToggle}
          handleContentType={handleContentType}
        />
        <div
          component="main"
          style={{
            flex: 1,
            zIndex: 1,
            marginLeft: !drawerOpen
              ? !isMobile
                ? "220px"
                : "0px"
              : isMobile
                ? "0px"
                : "20px",
            transition: "0.2s",
          }}
        >
          {contentType === "Dashboard" ? (
            <DashBoard />
          ) : contentType === "CountryMaster" ? (
            <Country />
          ) : contentType === "StateMaster" ? (
            <State />
          ) : contentType === "CityMaster" ? (
            <Suspense fallback={<Preloader />}>
              <CityMaster />
            </Suspense>
          ) : contentType === "SampleForm" ? (
            <SampleForm />
          ) : contentType === "ProfileUpdate" ? (
            <ProfileUpdate handleContentType={handleContentType} />
          ) : contentType === "UserRegistration" ? (
            <UserRegistration handleContentType={handleContentType} />
          ) : contentType === "AuditTrailReport" ? (
            <AuditTrail />
          ) : contentType === "Organization" ? (
            <Organization />
          ) : contentType === "E-Mail" ? (
            <Email />
          ) : (
            <PageNotFount />
          )}
        </div>
        <Dialog
          open={isDialogOpen}
          onClose={handleSessionExpire}
          ref={dialogRef}
          style={{ backdropFilter: "blur(8px)" }}
        >
          <DialogTitle>
            <Typography style={{ fontWeight: "bold" }}>
              {"Your session has expired due to inactivity."}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography>{"Please log in again to continue."}</Typography>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              onClick={handleSessionExpire}
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                paddingTop: "4px",
                paddingBottom: "4px",
              }}
              color="primary"
              variant="contained"
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
}

export default Header;
