import { useMediaQuery, useTheme } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  Autocomplete,
  Grid,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { apiUrl } from "../../apiConfig";
function SampleCountryStateCity() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [filterCountry, setFilterCountry] = useState(null);
  const [filterState, setFilterState] = useState(null);
  const [filterCity, setFilterCity] = useState(null);
  const [countryData, setCountryData] = useState([]);
  const [filterdStateData, setFilterdStateData] = useState([]);
  const [filterdCityData, setFilterdCityData] = useState([]);
  const [stateField, setStateField] = useState(true);
  const [cityField, setCityField] = useState(true);

  const fetchDataState = useCallback(
    async (state_name) => {
      try {
        const response = await axios.post(
          `${apiUrl}/statemaster/`,
          {
            apitype: "Get",
            country_name: state_name ? state_name?.CountryName : "India",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const responseData = response.data;
        setFilterdStateData(responseData.data);
        console.log(responseData.data);
      } catch (error) {
        console.log("Error fetching data: " + error.message);
      }
    },
    [accessToken]
  );

  const fetchDataCountry = useCallback(async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/countrymaster/`,
        {
          apitype: "Get",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const responseData = response.data;
      setCountryData(responseData.data);
    } catch (error) {
      console.log("Error fetching data: " + error.message);
    }
  }, [accessToken]);

  const fetchDataCity = useCallback(
    async (country_name, state_name) => {
      console.log(country_name, state_name);
      try {
        const response = await axios.post(
          `${apiUrl}/citymaster/`,
          {
            apitype: "Get",
            state_name: state_name ? state_name?.StateName : "Tamil Nadu",
            country_name: country_name ? country_name?.CountryName : "India",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const responseData = response.data;
        setFilterdCityData(responseData.data);
        console.log(responseData);
      } catch (error) {
        console.log("Error fetching data: " + error);
      }
    },
    [accessToken]
  );
  useEffect(() => {
    fetchDataCountry();
  }, [fetchDataCountry]);

  const handleCountryChange = (event, value) => {
    if (value) {
      setFilterCountry(value);
    } else {
      setFilterCountry(null);
    }
    fetchDataState(value);
    if (value === null) {
      setStateField(true);
    } else {
      setStateField(false);
    }

    console.log(value);
  };

  const handleStateChange = (event, value) => {
    if (value) {
      setFilterState(value);
    } else {
      setFilterState(null);
    }
    fetchDataCity(filterCountry, value);
    if (value === null) {
      setCityField(true);
    } else {
      setCityField(false);
    }
    console.log(filterState);
  };
  const handleCityChange = (event, value) => {
    if (value) {
      setFilterCity(value);
    } else {
      setFilterCity(null);
    }
  };
  const handleSubmit = () => {
    console.log(filterCountry, filterState, filterCity);
    console.log("Submit button clicked");
  };

  return (
    <>
      <div
        style={{
          width: "90%",
          backgroundColor: "white",
          padding: "20px",
          borderRadius: isMobile ? "0" : "15px",
          minHeight: "450px",
          marginTop: "120px",
          marginLeft: isMobile ? "3px" : "65px",
          marginRight: isMobile ? "3px" : "50px",
          marginBottom: "50px",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="20px"
        >
          <Typography
            variant="h4"
            component="h2"
            style={{
              fontFamily: "Times New Roman",
              fontSize: "22px",
              color: "rgb(94, 53, 177)",
              fontWeight: "bold",
            }}
          >
            Country | State | City
          </Typography>
        </Box>
        <Divider style={{ marginBottom: "20px" }} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Autocomplete
              size="small"
              autoFocus
              key={filterCountry ? filterCountry.CountryId : null}
              options={countryData}
              getOptionLabel={(option) => option.CountryName || ""}
              value={filterCountry}
              onChange={(event, value) => handleCountryChange(event, value)}
              renderInput={(params) => (
                <TextField {...params} label="Country" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              disabled={stateField}
              size="small"
              key={filterdStateData ? filterdStateData.StateId : null}
              options={filterdStateData}
              getOptionLabel={(option) => option.StateName || ""}
              value={filterState}
              onChange={(event, value) => handleStateChange(event, value)}
              renderInput={(params) => <TextField {...params} label="State" />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              disabled={cityField}
              size="small"
              key={filterdCityData ? filterdCityData.CityId : null}
              options={filterdCityData}
              getOptionLabel={(option) => option.CityName || ""}
              value={filterCity}
              onChange={(event, value) => handleCityChange(event, value)}
              renderInput={(params) => <TextField {...params} label="City" />}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              textAlign: isMobile ? "center" : "left",
              marginTop: isMobile ? "20px" : "5px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSubmit}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default SampleCountryStateCity;
