import React from 'react'
import '../Pages/LoginPage/LoginPage.css'

function Preloader() {
  return (
    <>
        <div className="loaderContainer">
            <div className="loader"></div>
          </div>
    </>
  )
}

export default Preloader