import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import {
  Box,
  Button,
  Divider,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
const initialUserData = {
  firstName: "",
  lastName: "",
  userName: "",
  password: "",
  confirmPassword: "",
  email: "",
  phoneNumber: "+91",
  role: "",
  country: "",
  address: "",
  city: "",
  state: "",
  pincode: "",
};
function ProfileUpdate({ handleContentType }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector((state) => state.user.user);
  const [selectedItem, setSelectedItem] = useState(user);
  const handleBackButton = () => {
    handleContentType("Dashboard");
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setSelectedItem((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  return (
    <div
      style={{
        width: "90%",
        backgroundColor: "white",
        padding: "20px",
        borderRadius: isMobile ? "0" : "15px",
        minHeight: "420px",
        marginTop: "120px",
        marginLeft: isMobile ? "3px" : "65px",
        marginRight: isMobile ? "3px" : "50px",
        marginBottom: "10px",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <Typography
          variant="h4"
          component="h2"
          style={{
            fontFamily: "Times New Roman",
            fontSize: "22px",
            color: "rgb(94, 53, 177)",
            fontWeight: "bold",
          }}
        >
          Profile Update
        </Typography>
      </Box>
      <Divider style={{ marginBottom: "20px" }} />
      <Typography
        variant="form"
        component={"form"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Grid
          container
          spacing={1.6}
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ width: "80%" }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="First Name"
              id="first_name"
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.first_name}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Last Name"
              id="last_name"
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.last_name}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Email"
              id="email"
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.email}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Password"
              id="password"
              disabled
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginBottom: "20px" }}
              type="password"
              onChange={handleChange}
              value={selectedItem.password}
              autoComplete="new-password"
            />
          </Grid> */}

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Phone Number"
              id="phone_number"
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.phone_number}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Role"
              id="role"
              variant="outlined"
              disabled
              size="small"
              fullWidth
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.role}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Country"
              id="country"
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.country}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <TextField
              label="Address"
              id="address"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.address}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="City"
              id="city"
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.city}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="State"
              id="state"
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.state}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Pincode"
              id="pincode"
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.pincode}
            />
          </Grid>
        </Grid>
      </Typography>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Button
          variant="contained"
          sx={{ marginLeft: "10px", marginTop: "10px" }}
          // onClick={(e) => handle(e, false)}
        >
          Update
        </Button>
        <Button
          variant="contained"
          sx={{ marginLeft: "10px", marginTop: "10px" }}
          color="error"
          onClick={handleBackButton}
        >
          Back to Dashboard
        </Button>
      </div>
    </div>
  );
}

export default ProfileUpdate;
