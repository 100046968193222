import { useTheme } from "@emotion/react";
import { Box, Divider, Typography, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-buttons-dt";
import "jszip";
import "pdfmake";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import axios from "axios";
import { apiUrl } from "../../apiConfig";

function AuditTrail() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const tableRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/commoditymaster/`,
        {
          apitype: "Get",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const responseData = response.data;
      setData(responseData.data);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching data: " + error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!loading && $.fn.DataTable.isDataTable(tableRef.current)) {
      const dataTable = $(tableRef.current).DataTable();
      dataTable.clear().rows.add(data).draw();
    }
  }, [data, loading]);

  useEffect(() => {
    if (
      !loading &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        data: data,
        colReorder: true,
        layout: {
          topStart: "search",
          topEnd: "buttons",
        },
        buttons: [
          {
            extend: "copy",
            className: "primary-button",
            text: '<span style="display: flex;align-items: center;"><i class="material-icons" style="font-size: 20px;margin-right: 8px">content_copy</i><span>Copy</span</span>',
          },
          {
            extend: "csv",
            className: "primary-button",
            text: '<span style="display: flex;align-items: center;"><i class="material-icons" style="font-size: 20px;margin-right: 8px">description</i><span>CSV</span></span>',
          },
          {
            extend: "excel",
            className: "primary-button",
            text: '<span style="display: flex;align-items: center;"><i class="material-icons" style="font-size: 20px;margin-right: 8px">description</i><span>Excel</span></span>',
          },
          {
            extend: "pdf",
            className: "primary-button",
            text: '<span style="display: flex;align-items: center;"><i class="material-icons" style="font-size: 20px;margin-right: 8px">picture_as_pdf</i><span>PDF</span></span>',
          },
          {
            extend: "print",
            className: "primary-button",
            text: '<span style="display: flex;align-items: center;"><i class="material-icons" style="font-size:  20px;margin-right: 8px">print</i><span>Print</span></span>',
          },
        ],
        language: {
          search: "",
          searchPlaceholder: "Search...",
        },
        columns: [
          {
            data: null,
            title: "Sl&nbsp;No",
            width: "5%",
            render: (data, type, row, meta) => meta.row + 1,
          },
          { data: "CommodityId", title: "ID", width: "5%", visible: false },
          { data: "CommodityName", title: "UserName", width: "25%" },
          { data: "CommodityName", title: "Description", width: "25%" },
          { data: "CommodityName", title: "Date Time", width: "25%" },
          { data: "CommodityName", title: "Status", width: "15%" },
        ],
        rowCallback: function (row, data, index) {
            const backgroundColor = index % 2 === 0 ? '#e6f2ff' : '#ffffff';
            const hoverColor = '#80bfff';
            $(row).css({
                'background-color': backgroundColor,
                'transition': 'background-color 0.3s ease',
                'cursor': 'pointer'
            });

            $(row).hover(
                function () {
                    $(this).css('background-color', hoverColor);
                },
                function () {
                    $(this).css('background-color', backgroundColor);
                }
            );
        },
        drawCallback: function () {
          //   const currentData = this.api().rows().data().toArray();
          //   $(".edit-btn").on("click", function () {
          //     const id = $(this).data("id");
          //     const item = currentData.find((d) => d.CommodityId === id);
          //     handleEdit(item);
          //   });
          //   $(".delete-btn").on("click", function () {
          //     const id = $(this).data("id");
          //     const item = currentData.find((d) => d.CommodityId === id);
          //     handleDeleteConfirmation(item);
          //   });
        },
      });

      $(".primary-button").css({
        "background-color": "#007bff",
        color: "white",
        padding: "6px 8px",
        fontsize: "12px",
      });

      $('.dataTables_filter input[type="search"]').addClass(
        "MuiInputBase-input"
      );
    }
  }, [loading, data, isMobile]);
  return (
    <>
      <div
        style={{
          width: "90%",
          backgroundColor: "white",
          padding: "20px",
          borderRadius: isMobile ? "0" : "15px",
          minHeight: "530px",
          marginTop: "120px",
          marginLeft: isMobile ? "3px" : "65px",
          marginRight: isMobile ? "3px" : "50px",
          marginBottom: "10px",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="20px"
        >
            <span style={{ fontFamily: 'Times New Roman', fontSize: '22px', color: 'rgb(94, 53, 177)', fontWeight: 'bold', marginTop: '0px', marginBottom: '0px', paddingTop: '10px' }}>Audit Trail</span>
        </Box>
        <Divider style={{ marginBottom: "20px" }} />
        <table
          ref={tableRef}
          className="display"
          style={{ width: "100%", border: "1px solid #003380" }}
        >
          <thead style={{ backgroundColor: "#00004d", color: "white" }}>
            <tr>
              <th>Sl&nbsp;No</th>
              <th>ID</th>
              <th>UserName</th>
              <th>Description</th>
              <th>Date Time</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </>
  );
}

export default AuditTrail;
