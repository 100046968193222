import React, { useState, useEffect } from "react";
import {
  Typography, Box, Divider, Checkbox, Grid
} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import Customer from "./Customer";
import Vendor from "./Vendor";
import Agent from "./Agent";


const OrganizationDetails = ({ onClose, item }) => {

  // console.log("Check category type name : ", item.CategoryTypeName);

  const [selectedCategory, setSelectedCategory] = useState('Customer');

  useEffect(() => {
    // When editing, set the checkbox based on the CategoryTypeName in the item
    if (item && item.CategoryTypeName) {
      setSelectedCategory(item.CategoryTypeName);
    }
  }, [item]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCheckboxChange = (event) => {
    setSelectedCategory(event.target.name);
  };

  const isEditing = !!item?.CategoryTypeName;

  return (
    <div
      style={{
        width: "90%",
        backgroundColor: "white",
        padding: "20px",
        borderRadius: isMobile ? "0" : "15px",
        minHeight: "750px",
        marginTop: "120px",
        marginLeft: isMobile ? "3px" : "65px",
        marginRight: isMobile ? "3px" : "50px",
        marginBottom: "50px",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <Typography
          variant="h4"
          component="h2"
          style={{
            fontFamily: "Times New Roman",
            fontSize: "22px",
            color: "#003380",
            fontWeight: "bold",
          }}
        >
          Organization&nbsp;Details
        </Typography>
      </Box>
      <Divider />
      <div style={{ marginTop: '50px' }}>
        <Grid container alignItems="center" style={{ marginTop: '30px', marginBottom: '50px' }} >
          <Grid item xs={12} md={6}>
            <Typography variant="h4" component="h2" style={{ fontFamily: 'Times New Roman', fontSize: '22px', color: '#003380', fontWeight: 'bold', marginTop: '5px', marginLeft: '50px', marginRight: '180px', }} >
              Category
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControlLabel
              control={<Checkbox style={{ color: '#003380' }} checked={selectedCategory === 'Customer'} onChange={handleCheckboxChange} name="Customer" disabled={isEditing && selectedCategory !== 'Customer'} />}
              label={<Typography style={{ fontFamily: 'Times New Roman', color: '#003380', fontWeight: 'bold', }} > Customer </Typography>}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControlLabel
              control={<Checkbox style={{ color: '#003380' }} checked={selectedCategory === 'Vendor'} onChange={handleCheckboxChange} name="Vendor" disabled={isEditing && selectedCategory !== 'Vendor'} />}
              label={<Typography style={{ fontFamily: 'Times New Roman', color: '#003380', fontWeight: 'bold', }} > Vendor </Typography>}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControlLabel
              control={<Checkbox style={{ color: '#003380' }} checked={selectedCategory === 'Agent'} onChange={handleCheckboxChange} name="Agent" disabled={isEditing && selectedCategory !== 'Agent'} />}
              label={<Typography style={{ fontFamily: 'Times New Roman', color: '#003380', fontWeight: 'bold', }} > Agent </Typography>}
            />
          </Grid>
        </Grid>
      </div>
      {selectedCategory === 'Customer' && <Customer onClose={onClose} item={item} />}
      {selectedCategory === 'Vendor' && <Vendor onClose={onClose} item={item} />}
      {selectedCategory === 'Agent' && <Agent onClose={onClose} item={item} />}
    </div>
  );
};

export default OrganizationDetails;
