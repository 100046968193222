import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import {
  Box,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import UserRegistrationDetails from "./UserRegistrationDetails";
import axios from "axios";
import { apiUrl } from "../../apiConfig";
import $ from "jquery";
import "datatables.net";
import "datatables.net-buttons-dt";
import "jszip";
import "pdfmake";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useSelector } from 'react-redux';

function UserRegistration({handleContentType}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modelType, setModelType] = useState(false);
  const tableRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const accessToken = useSelector((state) => state.auth.accessToken);
  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/getusers/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const responseData = response.data;
      setData(responseData.data);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching data: " + error.message);
      setLoading(false);
    }
  };  

  const handleEdit = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleDeleteConfirmation = useCallback((item) => {
    setModelType(true);
    setSelectedItem(item);
    setIsModalOpen(true);
  }, []);

  useEffect(() => {
    fetchData();
  },[]);

  useEffect(() => {
    if (!loading && $.fn.DataTable.isDataTable(tableRef.current)) {
      const dataTable = $(tableRef.current).DataTable();
      dataTable.clear().rows.add(data).draw();
    }
  }, [data, loading]);

  useEffect(() => {
    if (
      !loading &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        data: data,
        colReorder: true,
        layout: {
          topStart: "search",
          topEnd: "buttons",
        },
        buttons: [
          { extend: 'copy', className: 'primary-button',text: '<span style="display: flex;align-items: center;"><i class="material-icons" style="font-size: 20px;margin-right: 8px">content_copy</i><span>Copy</span</span>'},
          { extend: 'csv', className: 'primary-button',text:'<span style="display: flex;align-items: center;"><i class="material-icons" style="font-size: 20px;margin-right: 8px">description</i><span>CSV</span></span>'},
          { extend: 'excel', className: 'primary-button',text:'<span style="display: flex;align-items: center;"><i class="material-icons" style="font-size: 20px;margin-right: 8px">description</i><span>Excel</span></span>'},
          { extend: 'pdf', className: 'primary-button',text:  '<span style="display: flex;align-items: center;"><i class="material-icons" style="font-size: 20px;margin-right: 8px">picture_as_pdf</i><span>PDF</span></span>'},
          { extend: 'print', className: 'primary-button',text :'<span style="display: flex;align-items: center;"><i class="material-icons" style="font-size:  20px;margin-right: 8px">print</i><span>Print</span></span>'}
        ],
        language: {
          search: "",
          searchPlaceholder: "Search...",
        },
        columns: [
          {
            data: null,
            title: "Sl No",
            width: "2%",
            render: (data, type, row, meta) => meta.row + 1,
          },
          { data: "id", title: "User ID", width: "0", visible: false },
          { data: "first_name", title: "Name", width: "10%" },
          // { data: "last_name", title: "L. Name", width: "10%" },
          { data: "email", title: "Email", width: "10%" },
          { data: "phone_number", title: "Phone No.", width: "10%" },
          { data: "role", title: "Role", width: "10%" },
          { data: "address", title: "Address", width: "10%" },
          { data: "country", title: "Country", width: "10%" },
          { data: "state", title: "State", width: "10%" },
          { data: "city", title: "City", width: "10%" },
          {
            data: "is_active",
            title: "Status",
            width: "10%",
            render: (data, type, row) => {
              return data
                ? '<span style="color: green;">Active</span>'
                : '<span style="color: yellow;">Inactive</span>';
            },
          },
          // { data: "pincode", title: "Pincode", width: "10%" },
          {
            data: null,
            title: "Action",
            width: "15%",
            render: (data, type, row) => {

              return        `
                                <button class="edit-btn" data-id="${row.id}"><i class="material-icons">edit</i></button>
                                <button class="delete-btn" data-id="${row.id}"><i class="material-icons">delete</i></button>
                            `;
            },
          },
        ],
        rowCallback: function (row, data, index) {
          const backgroundColor = index % 2 === 0 ? "#e6f2ff" : "#ffffff";
          const hoverColor = "#80bfff";
          $(row).css({
            "background-color": backgroundColor,
            transition: "background-color 0.3s ease",
            cursor: "pointer",
          });

          $(row).hover(
            function () {
              $(this).css("background-color", hoverColor);
            },
            function () {
              $(this).css("background-color", backgroundColor);
            }
          );
        },
        drawCallback: function () {
          const currentData = this.api().rows().data().toArray();
          $(".edit-btn").on("click", function () {
            const id = $(this).data("id");
            const item = currentData.find((d) => d.id === id);
            handleEdit(item);
          });

          $(".delete-btn").on("click", function () {
            const id = $(this).data("id");
            const item = currentData.find((d) => d.id === id);
            handleDeleteConfirmation(item);
          });
        },
      });

      $(".primary-button").css({
        "background-color": "#007bff",
         color: "white",
         padding: "6px 8px",
         fontsize : "12px",
      });

      $('.dataTables_filter input[type="search"]').addClass(
        "MuiInputBase-input"
      );
    }
  }, [loading, data, handleDeleteConfirmation]);


  const handleNewButtonClick = () => {
    setSelectedItem(null);
    setIsModalOpen(true);
  };

  const handleCloseModal = (e) => {
    setIsModalOpen(e);
    setSelectedItem(null);
    setModelType(false);
    fetchData();;
  };
    return (
      <>
      {!isModalOpen && (
        <div
          style={{
            width: "90%",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: isMobile ? "0" : "15px",
            minHeight: "750px",
            marginTop: "120px",
            marginLeft: isMobile ? "3px" : "65px",
            marginRight: isMobile ? "3px" : "50px",
            marginBottom: "50px",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom="20px"
          >
            <Typography
              variant="h4"
              component="h2"
              style={{
                fontFamily: "Times New Roman",
                fontSize: "22px",
                color: "rgb(94, 53, 177)",
                fontWeight: "bold",
              }}
            >
              User Registration
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewButtonClick}
            >
            <AddCircleOutlineIcon sx={{ mr: 1 }} />
              New
            </Button>
          </Box>
          <Divider style={{ marginBottom: "20px" }} />
          <div style={{ marginTop: '20px' ,maxWidth:isMobile?'340px':'100%',overflowX:'auto'}} >
            <table
              ref={tableRef}
              className="display"
              style={{ width: "100%", border: "1px solid #003380" }}
            >
              <thead style={{ backgroundColor: "#00004d", color: "white" }}>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      )}
      {isModalOpen && (
        <UserRegistrationDetails
          modelType={modelType}
          selectedItems={selectedItem}
          onClose={handleCloseModal}
        />
      )}
    </>
    );
}

export default UserRegistration

