import React, { useCallback, useEffect, useState } from "react";
import {
  Typography,
  Box,
  Divider,
  Button,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import axios from "axios";
import { apiUrl } from "../../apiConfig";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import StateExcelUpload from "../../Components/ExcelUpload/StateExcelUpload";
import { useSelector } from "react-redux";
import { Autocomplete } from "@mui/material";
const StateDetails = ({ onClose, selectedItems }) => {
  const initialSelectedItem = {
    StateName: "",
    StateCode: "",
    Country_id: "",
    CountryName: "",
  };

  const [selectedItem, setSelectedItem] = useState(
    selectedItems || initialSelectedItem
  );
  const [openDialog, setOpenDialog] = useState(false); // State for dialog box
  const [errorFields, setErrorFields] = useState([]);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const requiredFields = ["StateName", "StateCode", "Country_id"];
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [filterCountry, setFilterCountry] = useState(null);
  const [countryData, setCountryData] = useState([]);
  const [countryNameField, setCountryNameField] = useState(true);
  useEffect(() => {
    console.log(selectedItem);
  }, [selectedItem]);
  const fetchDataCountry = useCallback(async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/countrymaster/`,
        {
          apitype: "Get",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const responseData = response.data;
      setCountryData(responseData.data);
    } catch (error) {
      console.log("Error fetching data: " + error.message);
    }
  }, [accessToken]);
  useEffect(() => {
    fetchDataCountry();
  }, [fetchDataCountry]);
  const handleSave = async (e) => {
    e.preventDefault();
    try {
      console.log(countryData);
      console.log(selectedItem);
      const apiType = selectedItem && selectedItem.StateId ? "Update" : "Post";
      const requestData = {
        apitype: apiType,
        state_id: selectedItem?.StateId || "",
        state_name: selectedItem.StateName || "",
        state_code: selectedItem.StateCode,
        country_id:
          countryData.find((cm) => cm.ISO2Code === selectedItem.Country_id)
            ?.CountryId || "",
        country_name: selectedItem.CountryName || "",
      };

      console.log("Request Data:", requestData);

      const response = await axios.post(`${apiUrl}/statemaster/`, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log("Response:", response.data);

      console.log("Response:", response.data);
      setSuccessDialogOpen(true); 
    } catch (error) {
      console.error("Error saving data:", error);
      setErrorDialogOpen(true); // Show error dialog on exception
    }
  };
  const handleChange = (field, value) => {
    setSelectedItem({ ...selectedItem, [field]: value });

    // Remove field from error list if user starts typing
    if (errorFields.includes(field)) {
      setErrorFields((prev) => prev.filter((f) => f !== field));
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedItem(initialSelectedItem);
    onClose();
  };
  const handleCloseBulk = () => {
    onClose();
  };

  const handleCountryChangeinvert = (event) => {
    const value = event.target.value;
    console.log(value);
    console.log(countryData);
    let isoCode =
      countryData.find((cm) => cm.CountryName === value)?.ISO2Code || "";
    setSelectedItem((prevSelectedItem) => ({
      ...prevSelectedItem,
      CountryName: value,
      Country_id: isoCode,
    }));
    setFilterCountry(value);
    console.log(selectedItem);
  };
  const handleCountryChange = (event) => {
    const value = event.target.value;
    console.log(value);
    console.log(countryData);
    let countryName =
      countryData.find((cm) => cm.ISO2Code === value)?.CountryName || "";
    console.log(countryName);
    setSelectedItem((prevSelectedItem) => ({
      ...prevSelectedItem,
      CountryName: countryName,
      Country_id: value,
    }));
    setFilterCountry(value);
    console.log(selectedItem);
  };
  return (
    <div
      style={{
        width: "90%",
        backgroundColor: "white",
        padding: "20px",
        borderRadius: isMobile ? "0" : "15px",
        minHeight: "500px",
        marginTop: "120px",
        marginLeft: isMobile ? "3px" : "65px",
        marginRight: isMobile ? "3px" : "50px",
        marginBottom: "50px",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <Typography
          variant="h4"
          component="h2"
          style={{
            fontFamily: "Times New Roman",
            fontSize: "22px",
            color: "#003380",
            fontWeight: "bold",
          }}
        >
          State&nbsp;Details
        </Typography>
      </Box>
      <Divider />
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{ marginTop: "15px",display:"flex",justifyContent:"center", alignContent:"center" }}
      >
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="h4"
              component="h2"
              style={{
                fontFamily: "Times New Roman",
                fontSize: "18px",
                color: "#003380",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Browse&nbsp;File
            </Typography>
            <StateExcelUpload onChange={handleCloseBulk} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          {!isMobile && (
            <Divider
              orientation="vertical"
              flexItem
              style={{
                marginTop: "-90px",
                backgroundColor: "#003380",
                width: "2px",
                height: "100%",
                minHeight: "220px",
                position: "absolute",
                top: "0",
                borderRadius: "2px",
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4} style={{ marginTop: "30px", marginRight: "20px" }}>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            {/* First row with two text fields */}
            <Grid item xs={12} sm={12} >
              <TextField
                size="small"
                label="State Name"
                variant="outlined"
                fullWidth
                value={selectedItem?.StateName || ""}
                onChange={(e) => handleChange("StateName", e.target.value)}
                inputProps={{ maxLength: 50 }}
                error={errorFields.includes("StateName")}
                helperText={errorFields.includes("StateName") ? "Required" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                size="small"
                label="State Code"
                variant="outlined"
                fullWidth
                value={selectedItem?.StateCode || ""}
                onChange={(e) => handleChange("StateCode", e.target.value)}
                inputProps={{ maxLength: 2 }}
                error={errorFields.includes("StateCode")}
                helperText={errorFields.includes("StateCode") ? "Required" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth size="small">
                <InputLabel id="country-code-label">CountryCode</InputLabel>
                <Select
                  labelId="country-code-label"
                  id="country-code-select"
                  value={countryData.find((cm) => cm.CountryId === selectedItem?.Country_id)?.ISO2Code || selectedItem.Country_id || ""}
                  onBlur={(event, value) => handleCountryChange(event, value)}
                  onChange={(event) =>
                    handleChange("Country_id", event.target.value)
                  }
                  label="CountryCode"
                >
                  {countryData.map((option) => (
                    <MenuItem key={option.CountryId} value={option.ISO2Code}>
                      {option.ISO2Code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControl fullWidth size="small">
                <InputLabel id="country-name-label">CountryName</InputLabel>
                <Select
                  labelId="country-name-label"
                  id="country-name-select"
                  value={selectedItem?.CountryName || ""}
                  onBlur={(event, value) =>
                    handleCountryChangeinvert(event, value)
                  }
                  onChange={(event) =>
                    handleChange("CountryName", event.target.value)
                  }
                  label="CountryName"
                >
                  {countryData.map((option) => (
                    <MenuItem key={option.CountryId} value={option.CountryName}>
                      {option.CountryName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                style={{ marginTop: "20px" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                >
                  Save
                </Button>
                <Button variant="contained" color="primary" onClick={onClose}>
                  Close
                </Button>
              </Box>
            </Grid>
          </Grid>
          
        </Grid>
      </Grid>
  
      <Dialog
        open={successDialogOpen}
        onClose={() => successDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{selectedItem && selectedItem.StateId ? "Record Updated" : "New Record Added"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { selectedItem && selectedItem.StateId ? "The record has been updated successfully!" : "The new record has been added successfully!"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StateDetails;
