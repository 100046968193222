import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  DialogContentText,
} from "@mui/material";
import Papa from "papaparse";
import axios from "axios";
import { apiUrl } from "../../apiConfig";
import { useSelector } from "react-redux";
import { LinearProgress, useMediaQuery, useTheme } from "@material-ui/core";
import '../../index.css'

const CustomerExcelUpload = ({ onFileUpload }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [tableData, setTableData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const rowsPerPage = 500;
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isDragging, setIsDragging] = useState(false);
  
  const requiredColumnsMapping = {
    "SL_NO": "Sl_No",
    "COMPANY NAME": "Company_Name",
    "CONTACT PERSON": "Contact_Person",
    "DESIGNATION": "Designation",
    "MOBILE NUMBER": "Mobile_Number",
    "TELE NUMBER": "Tele_Number",
    "WEBSITE": "Website",
    "EMAIL ID": "Email_ID",
    "COUNTRY": "Country",
    "STATE": "State",
    "CITY": "City",
    "PINCODE": "Pincode",
    "CATEGORY": "Category_Status",
    "STATUS": "Status",
    "TYPE / SERVICE": "Vendor_Type",
    "LOCATION": "Vendor_Location",
    "REFERENCE NAME": "Reference"
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setError("");
    parseFile(selectedFile);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const selectedFile = event.dataTransfer?.files?.[0];
    setFile(selectedFile);
    setError("");
    parseFile(selectedFile);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };


  // const parseFile = (selectedFile) => {
  //   if (!selectedFile) {
  //     setError("Please select a file.");
  //     return;
  //   }
  
  //   Papa.parse(selectedFile, {
  //     header: true,
  //     skipEmptyLines: true,
  //     complete: (result) => {
  //       console.log("Parsed Headers:", result.meta.fields);  // Log headers to inspect
  //       console.log("Parsed Data:", result.data.slice(0, 5)); // Log first 5 rows to inspect
  
  //       // Map actual columns to required columns
  //       const mappedData = result.data.map((row) => {
  //         let mappedRow = {};
  //         for (const key in requiredColumnsMapping) {
  //           if (row.hasOwnProperty(key.trim())) {
  //             mappedRow[requiredColumnsMapping[key]] = row[key.trim()] ? row[key.trim()].trim() : "";
  //           } else {
  //             mappedRow[requiredColumnsMapping[key]] = ""; // Set empty if column is missing
  //           }
  //         }
  //         return mappedRow;
  //       });
  
  //       console.log("Mapped Data:", mappedData);
  //       onFileUpload(mappedData);

  //       setTableData(mappedData);
  //       setDisplayedData(mappedData);
  //       setOpen(true); // Open dialog box
  //     },
  //     error: (err) => {
  //       setError("Error parsing CSV file.");
  //       console.error(err);
  //     },
  //   });
  // };


  const parseFile = (selectedFile) => {
    if (!selectedFile) {
      setError("Please select a file.");
      return;
    }

    Papa.parse(selectedFile, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        console.log("Parsed Headers:", result.meta.fields); // Log headers to inspect
        console.log("Parsed Data:", result.data.slice(0, 5)); // Log first 5 rows to inspect

        // Map actual columns to required columns
        const mappedData = result.data.map((row) => {
          let mappedRow = {};
          for (const key in requiredColumnsMapping) {
            if (row.hasOwnProperty(key.trim())) {
              mappedRow[requiredColumnsMapping[key]] = row[key.trim()] ? row[key.trim()].trim() : "";
            } else {
              mappedRow[requiredColumnsMapping[key]] = ""; // Set empty if column is missing
            }
          }
          return mappedRow;
        });

        // Filter only 'Customer' category rows
        const filteredData = mappedData.filter(row => row.Category_Status === "Vendor");

        console.log("Filtered Data:", filteredData);
        onFileUpload(filteredData);

        setTableData(filteredData);
        setDisplayedData(filteredData);
        setOpen(true); // Open dialog box
      },
      error: (err) => {
        setError("Error parsing CSV file.");
        console.error(err);
      },
    });
  };
  

  
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  
  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
    // onChange();
  };


  return (
    <div>
      <form className="file-upload-form">
        <label
          htmlFor="file"
          className={`file-upload-label ${isDragging ? 'dragging' : ''}`}
          style={{
            width: isMobile ? '250px' : '450px',
            height: isMobile ? '200px' : '',
          }}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          <div className="file-upload-design">
            <svg viewBox="0 0 640 512" height="1em">
              <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
            </svg>
            <p>Drag and Drop Attachment</p>
            <p>or</p>
            <span className="browse-button">Browse file</span>
          </div>
          <input
            id="file"
            type="file"
            accept=".csv"
            onChange={handleFileChange}
          />
        </label>
      </form>
      {error && <p style={{ color: "red" }}>{error}</p>}

      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>Uploaded Data</DialogTitle>
        {isLoading && (
          <>
            <LinearProgress />
            <p style={{ textAlign: "center" }}>
              Uploading...Please wait for a while
            </p>
          </>
        )}
        {!isLoading && (
          <DialogContent>
            <p style={{ textAlign: "center" }}>
              You are about to upload {displayedData.length} records.
            </p>
          </DialogContent>
        )}
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          {/* <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
          >
            Save
          </Button> */}
          <Button
            disabled={isLoading}
            variant="contained"
            onClick={() => setOpen(false)}
            color="primary"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>

      {/* Error Dialog for Existing File */}
      <Dialog
        open={duplicateDialogOpen}
        onClose={() => setDuplicateDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Issue in Uploading Data
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The data you are trying to save has an Issue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDuplicateDialogOpen(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Dialog */}
      <Dialog open={successDialogOpen} onClose={handleSuccessDialogClose}>
        <DialogTitle>Details Added Successfully</DialogTitle>
        <DialogContent>
          <p>Your details have been successfully added.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomerExcelUpload;
