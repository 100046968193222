import React, { useState } from "react";
import {
  Typography,
  Box,
  Divider,
  Button,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CountryExcelUpload from "../../Components/ExcelUpload/CountryExcelUpload";
import axios from "axios";
import { apiUrl } from "../../apiConfig";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";

const CountryDetails = ({ onClose, item }) => {
  const initialSelectedItem = {
    CountryName: "",
    ISO2Code: "",
    ISO3Code: "",
    PhoneCode: "",
    CapitalName: "",
    Currency: "",
    CurrencyName: "",
  };

  const [selectedItem, setSelectedItem] = useState(item || initialSelectedItem);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false); // State for error dialog
  const [existingDialogOpen, setExistingDialogOpen] = useState(false);
  // State for existing country dialog
  const [errorFields, setErrorFields] = useState([]); // State to track fields with errors
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const accessToken = useSelector((state) => state.auth.accessToken);
  const requiredFields = [
    "CountryName",
    "ISO2Code",
    "ISO3Code",
    "PhoneCode",
    "CapitalName",
    "Currency",
    "CurrencyName",
  ];

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      // Check for required fields
      const missingFields = requiredFields.filter(
        (field) => !selectedItem?.[field]
      );
      if (missingFields.length > 0) {
        setErrorFields(missingFields);
        throw new Error(
          `Following fields are required: ${missingFields.join(", ")}`
        );
      }

      // Check if the country already exists in the DataTable
      const existingCountry = await checkExistingData(selectedItem.CountryName);
      if (existingCountry) {
        setExistingDialogOpen(true);
        return; // Exit function to prevent further execution
      }

      const apiType =
        selectedItem && selectedItem.CountryId ? "Update" : "Post";
      const requestData = {
        apitype: apiType,
        country_id: selectedItem?.CountryId || "",
        country_name: selectedItem?.CountryName || "",
        country_iso2: selectedItem?.ISO2Code || "",
        country_iso3: selectedItem?.ISO3Code || "",
        country_phno_code: selectedItem?.PhoneCode || "",
        country_capital: selectedItem?.CapitalName || "",
        country_currency: selectedItem?.Currency || "",
        country_currencyname: selectedItem?.CurrencyName || "",
      };

      console.log("Request Data:", requestData); // Log the request data for debugging

      const response = await axios.post(
        `${apiUrl}/countrymaster/`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      console.log("Response:", response.data);
      if (response.data.success) {
        setSuccessDialogOpen(true); // Show success dialog on successful save
      } else {
        setErrorDialogOpen(true); // Show error dialog on failed save
      }
    } catch (error) {
      console.error("Error saving data:", error);
      setErrorDialogOpen(true); // Show error dialog on exception
    }
  };

  const handleSuccessDialogOpen = () => {
    setSuccessDialogOpen(true);
  };
  
  const handleSuccessDialogClose = () => {
    console.log("Closing success dialog");
    setSuccessDialogOpen(false);
    setSelectedItem(initialSelectedItem);
    onClose();
  };

  const handleChange = (field, value) => {
    setSelectedItem({ ...selectedItem, [field]: value });

    if (errorFields.includes(field)) {
      setErrorFields((prev) => prev.filter((f) => f !== field));
    }
  };

  const handleExistingDialogClose = () => {
    setExistingDialogOpen(false);
  };

  const handleclosedetails = () => {
    onClose(false);
  };

  const checkExistingData = async (countryName) => {
    try {
      // Replace with your actual API call to check if countryName exists in your DataTable
      const response = await axios.get(
        `${apiUrl}/countrymaster/exists/${countryName}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      console.log("Existing Data Check Response:", response.data);

      return response.data.exists; // Assuming the response indicates whether the country exists or not
    } catch (error) {
      console.error("Error checking existing data:", error.message);
      return false; // Return false by default on error or no data found
    }
  };

  return (
    <div
      style={{
        width: "90%",
        backgroundColor: "white",
        padding: "20px",
        borderRadius: isMobile ? "0" : "15px",
        minHeight: "500px",
        marginTop: "120px",
        marginLeft: isMobile ? "3px" : "65px",
        marginRight: isMobile ? "3px" : "50px",
        marginBottom: "50px",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <Typography
          variant="h4"
          component="h2"
          style={{
            fontFamily: "Times New Roman",
            fontSize: "22px",
            color: "#003380",
            fontWeight: "bold",
          }}
        >
          Country&nbsp;Details
        </Typography>
      </Box>
      <Divider />
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{
          marginTop: "15px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="h4"
              component="h2"
              style={{
                fontFamily: "Times New Roman",
                fontSize: "18px",
                color: "#003380",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Browse&nbsp;File
            </Typography>
            <CountryExcelUpload onChange={handleclosedetails} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          {!isMobile && (
            <Divider
              orientation="vertical"
              flexItem
              style={{
                marginTop: "-90px",
                backgroundColor: "#003380",
                width: "2px",
                height: "100%",
                minHeight: "220px",
                position: "absolute",
                top: "0",
                borderRadius: "2px",
              }}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{ marginTop: "30px", marginRight: "20px" }}
        >
          <Grid container spacing={2}>
            {/* First row with two text fields */}
            <Grid item xs={12} sm={12} style={{ marginTop: "20px" }}>
              <TextField
                label="Country Name"
                variant="outlined"
                fullWidth
                size="small"
                value={selectedItem?.CountryName || ""}
                onChange={(e) => handleChange("CountryName", e.target.value)}
                inputProps={{ maxLength: 50 }}
                error={errorFields.includes("CountryName")}
                helperText={
                  errorFields.includes("CountryName") ? "Required" : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Country ISO2"
                variant="outlined"
                fullWidth
                size="small"
                value={selectedItem?.ISO2Code || ""}
                onChange={(e) => handleChange("ISO2Code", e.target.value)}
                inputProps={{ maxLength: 2 }}
                error={errorFields.includes("ISO2Code")}
                helperText={errorFields.includes("ISO2Code") ? "Required" : ""}
              />
            </Grid>

            {/* Second row with one text field */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Country ISO3"
                variant="outlined"
                fullWidth
                size="small"
                value={selectedItem?.ISO3Code || ""}
                onChange={(e) => handleChange("ISO3Code", e.target.value)}
                inputProps={{ maxLength: 3 }}
                error={errorFields.includes("ISO3Code")}
                helperText={errorFields.includes("ISO3Code") ? "Required" : ""}
              />
            </Grid>

            {/* Third row with three text fields */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone Code"
                variant="outlined"
                fullWidth
                size="small"
                value={selectedItem?.PhoneCode || ""}
                onChange={(e) => handleChange("PhoneCode", e.target.value)}
                inputProps={{ maxLength: 10 }}
                error={errorFields.includes("PhoneCode")}
                helperText={errorFields.includes("PhoneCode") ? "Required" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Capital Name"
                variant="outlined"
                fullWidth
                size="small"
                value={selectedItem?.CapitalName || ""}
                onChange={(e) => handleChange("CapitalName", e.target.value)}
                inputProps={{ maxLength: 50 }}
                error={errorFields.includes("CapitalName")}
                helperText={
                  errorFields.includes("CapitalName") ? "Required" : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Currency"
                variant="outlined"
                fullWidth
                size="small"
                value={selectedItem?.Currency || ""}
                onChange={(e) => handleChange("Currency", e.target.value)}
                inputProps={{ maxLength: 3 }}
                error={errorFields.includes("Currency")}
                helperText={errorFields.includes("Currency") ? "Required" : ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Currency Name"
                variant="outlined"
                fullWidth
                size="small"
                value={selectedItem?.CurrencyName || ""}
                onChange={(e) => handleChange("CurrencyName", e.target.value)}
                inputProps={{ maxLength: 50 }}
                error={errorFields.includes("CurrencyName")}
                helperText={
                  errorFields.includes("CurrencyName") ? "Required" : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                style={{ marginTop: "20px" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                >
                  Save
                </Button>
                <Button variant="contained" color="primary" onClick={onClose}>
                  Close
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={successDialogOpen}
        onClick={() => setSuccessDialogOpen(true)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Success</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {item
              ? "Record Updated Successfully!"
              : "New Record Added Successfully!"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Error Dialog */}
      <Dialog
        open={errorDialogOpen}
        onClose={() => setErrorDialogOpen(true)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Duplicate</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            CountryDetails are already exists in the list.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setErrorDialogOpen(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Existing Country Dialog */}
      <Dialog
        open={existingDialogOpen}
        onClose={() => setExistingDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Country Already Exists
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The country you are trying to add already exists in the list.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setExistingDialogOpen(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CountryDetails;
