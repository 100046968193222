import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import React  from "react";
import BusinessIcon from '@mui/icons-material/Business';
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import PostAddIcon from '@mui/icons-material/PostAdd';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
const CardComponent =  ({ title, count, content,subcontent, buttonText, onButtonClick,icons }) => {
    let IconComponent = null;

  // Conditionally set IconComponent based on the icon prop
  switch (icons) {
    case 'SupportAgentIcon':
      IconComponent = SupportAgentIcon;
      break;
    case 'BusinessIcon':
      IconComponent = BusinessIcon;
      break;
    case 'PostAddIcon':
      IconComponent = PostAddIcon;
      break;
    case 'FactCheckIcon':
      IconComponent = FactCheckIcon;
      break;
    case 'FileUploadIcon':
      IconComponent = FileUploadIcon;
      break;
    case 'DepartureBoardIcon':
      IconComponent = DepartureBoardIcon;
      break;
    case 'AccessTimeIcon':
      IconComponent = AccessTimeIcon;
      break;
    case 'CallReceivedIcon':
      IconComponent = CallReceivedIcon;
      break;
    case 'PendingActionsIcon':
      IconComponent = PendingActionsIcon;
      break;
    default:
      IconComponent = null;
      break;
  }
    return (
      <Card onClick={onButtonClick} sx={{ minWidth: 275, mb: 2, backgroundColor: title, color: 'white' }}>
  <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
      {IconComponent && <IconComponent sx={{ fontSize: 60, color: 'white' }} />} {/* Icon on the left */}
      <Typography variant="h5" component="div" sx={{ fontSize: 40, ml: 'auto' }}>
        {count} {/* Count on the right */}
      </Typography>
    </Box>
    <Typography variant="body2" sx={{fontWeight: 'bold', ml:'auto', mt: 1 }}>
      {content} {/* Content below the icon and count */}
    </Typography>
    <Typography variant="body2" sx={{ opacity: 0.7, ml:'auto', mt: 1 }}>
      {subcontent} 
    </Typography>
  </CardContent>
  <Divider />
  {/* <CardActions>
    <Button size="small" sx={{ color: 'white' }} onClick={onButtonClick}>
      {buttonText}
    </Button>
  </CardActions> */}
</Card>

    );
  };

  export default CardComponent;