import React, { useEffect, useRef, useState, useCallback } from "react";
import { Typography, Box, Divider, FormControl, InputLabel, Select, MenuItem, TextField, Grid, CircularProgress, Autocomplete, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";
import { apiUrl } from "../../apiConfig";
import $ from "jquery";
import "datatables.net";
import "datatables.net-buttons-dt";
import "jszip";
import "pdfmake";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import { useSelector } from "react-redux";

import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import SuccessIcon from '../../assets/images/success.gif';
import ErrorIcon from '../../assets/images/failure.gif';

import UploadIcon from '@mui/icons-material/Upload';
import { styled } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
// import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import UploadFileIcon from '@mui/icons-material/UploadFile';

// Styled File Input Container
const FileInputContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    border: '2px dashed #007BFF',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    cursor: 'pointer',
    maxWidth: '380px',
    minWidth: '620px', // Minimum width
    minHeight: '180px', // Minimum height
    overflowX: 'auto',
    margin: '0 auto',
    textAlign: 'center',
}));

// Styled File Input
const FileInput = styled('input')({
    display: 'none',
});

// Styled Button
const UploadButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(7),
    backgroundColor: '#007BFF',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#0056b3',
    },
}));

// Styled Typography with Icon
const TypographyWithIcon = styled(Typography)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '1.2rem', // Adjust the text size as needed
}));


const Email = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const tableRef = useRef(null);
    const selectedData = useRef(new Set()); // Use useRef to maintain state across renders
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const accessToken = useSelector((state) => state.auth.accessToken);
    const [errors, setErrors] = useState({});
    const [categoryName, setCategoryName] = useState('All');

    const [mailTO, setMailTO] = useState('');
    const [mailCC, setMailCC] = useState('');
    const [mailBCC, setMailBCC] = useState('');

    const [registerCountry, setRegisterCountry] = useState(null);
    const [registerState, setRegisterState] = useState(null);
    const [registerCity, setRegisterCity] = useState(null);
    const [registerStateData, setRegisterStateData] = useState([]);
    const [registerCityData, setRegisterCityData] = useState([]);
    const [registerStateField, setRegisterStateField] = useState(true);
    const [registerCityField, setRegisterCityField] = useState(true);

    const [countryData, setCountryData] = useState([]);


    const [registerPincodeData, setRegisterPincodeData] = useState([]);
    const [registerPincodeField, setRegisterPincodeField] = useState(false);
    const [registerpincode, setRegisterPincode] = useState(null);


    const [membershipNameData, setMembershipNameData] = useState([]);
    const [membershipNameField, setMembershipNameField] = useState(false);
    const [membershipname, setMembershipName] = useState(null);

    const [membershipTypeData, setMembershipTypeData] = useState([]);
    const [membershipTypeField, setMembershipTypeField] = useState(false);
    const [membershiptype, setMembershipType] = useState(null);

    const [membershipNumberData, setMembershipNumberData] = useState([]);
    const [membershipNumberField, setMembershipNumberField] = useState(false);
    const [membershipnumber, setMembershipNumber] = useState(null);


    const dialogRef = useRef(null);
    const failureRef = useRef(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [failureDialog, setFailureDialog] = useState(false);

    const handleFailureClose = () => {
        setFailureDialog(false); // Function to close the dialog
    };

    const handleCancelled = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpenDialog(false);
        }
    };

    // Fetch country data
    const fetchDataCountry = useCallback(async () => {
        try {
            const response = await axios.post(
                `${apiUrl}/countrymaster/`,
                { apitype: "Get" },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            setCountryData(response.data.data);
        } catch (error) {
            console.log("Error fetching country data: " + error.message);
        }
    }, [apiUrl, accessToken]);

    // Fetch state data
    const fetchDataState = useCallback(
        async (country, setStateData) => {
            try {
                const response = await axios.post(
                    `${apiUrl}/statemaster/`,
                    { apitype: "Get", country_name: country ? country.CountryName : "India" },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
                setStateData(response.data.data);
            } catch (error) {
                console.log("Error fetching state data: " + error.message);
            }
        },
        [apiUrl, accessToken]
    );

    // Fetch city data
    const fetchDataCity = useCallback(
        async (country, state, setCityData) => {
            try {
                const response = await axios.post(
                    `${apiUrl}/citymaster/`,
                    {
                        apitype: "Get",
                        state_name: state ? state.StateName : "Tamil Nadu",
                        country_name: country ? country.CountryName : "India",
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
                setCityData(response.data.data);
            } catch (error) {
                console.log("Error fetching city data: " + error.message);
            }
        },
        [apiUrl, accessToken]
    );

    useEffect(() => {
        fetchDataCountry();
    }, [fetchDataCountry]);

    const handleRegisterCountryChange = (event, value) => {
        setRegisterCountry(value);
        setRegisterState(null);
        setRegisterCity(null);
        fetchDataState(value, setRegisterStateData);
        setRegisterStateField(!value);
    };

    const handleRegisterStateChange = (event, value) => {
        setRegisterState(value);
        setRegisterCity(null);
        fetchDataCity(registerCountry, value, setRegisterCityData);
        setRegisterCityField(!value);
    };

    const handleRegisterCityChange = (event, value) => {
        setRegisterCity(value);
    };

    const handleRegisterPincodeChange = (event, value) => {
        setRegisterPincode(value ? value.Pincode : null);
    };

    const handleMembershipNameChange = (event, value) => {
        setMembershipName(value ? value.MembershipName : null);
    };

    const handleMembershipTypeChange = (event, value) => {
        setMembershipType(value ? value.MembershipType : null);
    };

    const handleMembershipNumberChange = (event, value) => {
        setMembershipNumber(value ? value.MembershipNumber : null);
    };

    const handleChange = (event) => {
        setCategoryName(event.target.value); // Update state with selected value
    };

    const fetchData = async () => {
        setLoading(true);
        console.log('Membership Name:', membershipname);
        try {
            const response = await axios.post(
                `${apiUrl}/organizationdetailsmailget/`,
                {
                    CategoryTypeName: categoryName,
                    RegisterCountry: registerCountry ? registerCountry.CountryName : "",
                    RegisterState: registerState ? registerState.StateName : "",
                    RegisterCity: registerCity ? registerCity.CityName : "",
                    RegisterPincode: registerpincode,
                    MembershipName: membershipname,
                    MembershipType: membershiptype,
                    MembershipNumber: membershipnumber,
                    // RegisterPincode: registerpincode ? registerpincode.Pincode : "",
                    // MembershipName: membershipname ? membershipname.MembershipName : "",
                    // MembershipType: membershiptype ? membershiptype.MembershipType : "",
                    // MembershipNumber: membershipnumber ? membershipnumber.MembershipNumber : "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            const responseData = response.data;
            console.log("Check data 1", responseData);

            // Set the data
            setData(responseData);

            // Extract unique pincodes
            const uniquePincodes = [...new Set(responseData.map(item => item.RegisterPincode))];
            setRegisterPincodeData(uniquePincodes.map(pincode => ({ Pincode: pincode })));

            // Extract unique Membership Names and Types
            const uniqueMembershipNames = [...new Set(responseData.map(item => item.MembershipName))];
            setMembershipNameData(uniqueMembershipNames.map(name => ({ MembershipName: name })));

            const uniqueMembershipTypes = [...new Set(responseData.map(item => item.MembershipType))];
            setMembershipTypeData(uniqueMembershipTypes.map(type => ({ MembershipType: type })));

            // Extract unique Membership Numbers
            const uniqueMembershipNumbers = [...new Set(responseData.map(item => item.MembershipNumber))];
            setMembershipNumberData(uniqueMembershipNumbers.map(number => ({ MembershipNumber: number })));

            setLoading(false);
        } catch (error) {
            console.log("Error fetching data: " + error.message);
            setLoading(false);
            setErrors({ category_name: "Failed to fetch data" });
        }
    };


    useEffect(() => {
        console.log("First useEffect");
        // console.log("Check category name", categoryName, registerPincode);
        fetchData();
    }, [categoryName, registerCountry, registerState, registerCity, registerpincode, membershipname, membershiptype, membershipnumber]);


    useEffect(() => {
        console.log("Main Effect");
        let dataTable = null; // Declare dataTable variable

        if (!loading && tableRef.current && !$.fn.DataTable.isDataTable(tableRef.current)) {
            dataTable = $(tableRef.current).DataTable({
                data: data,
                colReorder: true,
                lengthMenu: [
                    [10, 25, 50, -1],
                    [10, 25, 50, "All"],
                ],
                buttons: [
                    { extend: "copy", className: "primary-button" },
                    { extend: "csv", className: "primary-button" },
                    { extend: "excel", className: "primary-button" },
                    { extend: "pdf", className: "primary-button" },
                    { extend: "print", className: "primary-button" },
                ],
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                },
                columns: [
                    {
                        data: null,
                        title: "SL.No",
                        width: "3%",
                        render: (data, type, row, meta) => meta.row + 1,
                    },
                    {
                        data: null,
                        title: 'Select',
                        width: "5%",
                        render: function (data, type, row, meta) {
                            return '<input type="checkbox" class="row-select" value="' + row.OrganizationDetailsId + '" />';
                        },
                        orderable: false,
                    },
                    { data: "OrganizationDetailsId", title: "ID", visible: false },
                    { data: "CategoryTypeName", title: "Category Type Name", width: "20%" },
                    { data: "ContactPersonName", title: "Person Name", width: "15%" },
                    { data: "Email", title: "E-Mail", width: "10%" },
                ],
                fixedColumns: {
                    leftColumns: 2,
                },
                rowCallback: function (row, data, index) {
                    if (!dataTable) return; // Ensure dataTable is initialized

                    const backgroundColor = index % 2 === 0 ? "#e6f2ff" : "#ffffff";
                    const hoverColor = "#80bfff";
                    $(row).css({
                        "background-color": backgroundColor,
                        transition: "background-color 0.3s ease",
                        cursor: "pointer",
                    });

                    $(row).hover(
                        function () {
                            $(this).css("background-color", hoverColor);
                        },
                        function () {
                            $(this).css("background-color", backgroundColor);
                        }
                    );

                    // Handle row click to print all columns
                    $(row).on("click", function () {
                        console.log("Clicked Row Data:", data); // Log all columns of the clicked row
                    });

                    // Update row checkbox state based on selection
                    const rowData = dataTable.row(row).data(); // Ensure dataTable.row(row) exists
                    if (rowData) {
                        if (selectedData.current.has(rowData.OrganizationDetailsId)) {
                            $(row).find(".row-select").prop("checked", true);
                        } else {
                            $(row).find(".row-select").prop("checked", false);
                        }
                    }
                },
                initComplete: function () {
                    // Handle row checkbox change event
                    $(tableRef.current).on("change", ".row-select", function () {
                        const isChecked = this.checked;
                        const row = $(this).closest("tr");
                        const rowData = dataTable.row(row).data();

                        if (rowData) {
                            if (isChecked) {
                                selectedData.current.add(rowData.OrganizationDetailsId);
                            } else {
                                selectedData.current.delete(rowData.OrganizationDetailsId);
                                $("#select-all").prop("checked", false); // Uncheck "select all" if a row is unchecked
                            }
                        }

                        // Print Email column data for selected row
                        const email = rowData && rowData.Email ? rowData.Email : "";
                        console.log("Selected Email:", email);

                        // Print all selected emails
                        const selectedEmails = Array.from(selectedData.current).map(orgId => {
                            const selectedRow = dataTable.rows().data().filter(row => row.OrganizationDetailsId === orgId)[0];
                            return selectedRow && selectedRow.Email ? selectedRow.Email : "";
                        });

                        console.log("All Selected Emails:", selectedEmails);
                        setMailBCC(selectedEmails.join(","));
                    });
                },
            });

            $(".primary-button").css({
                "background-color": "#007bff",
                color: "white",
            });

            $('.dataTables_filter input[type="search"]').addClass("MuiInputBase-input");
        }

        return () => {
            if ($.fn.DataTable.isDataTable(tableRef.current)) {
                $(tableRef.current).DataTable().destroy(true);
            }
        };
    }, [data, loading]);


    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChange = (event) => {
        setSelectedFiles([...event.target.files]);
    };

    const isAgent = categoryName === 'Agent';
    // const [isLoader, setIsLoader] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Function to validate a list of email addresses
    const validateEmails = (emails) => {
        const emailList = emails.split(',').map(email => email.trim());
        for (const email of emailList) {
            if (email && !isValidEmail(email)) {
                return false;
            }
        }
        return true;
    };

    const handleSave = async (e) => {
        e.preventDefault();
        // setIsLoader(true);
        setLoadingDialog(true);
        try {

            const emailTo = document.getElementById('MailTo').value;
            const emailCc = document.getElementById('MailCC').value;
            const emailBcc = document.getElementById('MailBCC').value;

            const emailSubject = document.getElementById('MailSubject').value;
            const emailMailText = document.getElementById('MailText').value;

            // Validate "To" email fields
            if (!emailTo || !validateEmails(emailTo)) {
                setErrorMessage('Invalid or empty "To" email address');
                setLoadingDialog(false);
                setFailureDialog(true);
                return;
            }

            // Validate "CC" email fields
            if (emailCc && !validateEmails(emailCc)) {
                setErrorMessage('Invalid "CC" email address');
                setLoadingDialog(false);
                setFailureDialog(true);
                return;
            }

            // Validate "BCC" email fields
            if (emailBcc && !validateEmails(emailBcc)) {
                setErrorMessage('Invalid "BCC" email address');
                setLoadingDialog(false);
                setFailureDialog(true);
                return;
            }

            // if (!emailTo) {
            //     setErrorMessage('Email is empty');
            //     setIsLoader(false);
            //     setFailureDialog(true);
            //     return;
            // }

            // // Validate email fields
            // if (!emailTo || !isValidEmail(emailTo)) {
            //     setErrorMessage('Invalid or empty "To" email address');
            //     // setIsLoader(false);
            //     setLoadingDialog(false);
            //     setFailureDialog(true);
            //     return;
            // }

            // Validate subject fields
            if (!emailSubject) {
                setErrorMessage('Please fill out the mail Subject');
                // setIsLoader(false);
                setLoadingDialog(false);
                setFailureDialog(true);
                return;
            }

            // Validate mailText fields
            if (!emailMailText) {
                setErrorMessage('Please fill out the mail Text');
                // setIsLoader(false);
                setLoadingDialog(false);
                setFailureDialog(true);
                return;
            }

            // if (emailCc && !isValidEmail(emailCc)) {
            //     setErrorMessage('Invalid "CC" email address');
            //     // setIsLoader(false);
            //     setLoadingDialog(false);
            //     setFailureDialog(true);
            //     return;
            // }

            // if (emailBcc && !isValidEmail(emailBcc)) {
            //     setErrorMessage('Invalid "BCC" email address');
            //     // setIsLoader(false);
            //     setLoadingDialog(false);
            //     setFailureDialog(true);
            //     return;
            // }

            const formData = new FormData();

            // Append text fields
            formData.append('apitype', 'Post');
            formData.append('categorytype_name', categoryName);
            formData.append('country_name', registerCountry ? registerCountry.CountryName : "");
            formData.append('state_name', registerState ? registerState.StateName : "");
            formData.append('city_name', registerCity ? registerCity.CityName : "");
            // formData.append('pincode', registerpincode ? registerpincode.Pincode : "");
            // formData.append('membership', membershipname ? membershipname.MembershipName : "");
            // formData.append('membership_type', membershiptype ? membershiptype.MembershipType : "");
            // formData.append('membership_number', membershipnumber ? membershipnumber.MembershipNumber : "");
            formData.append('pincode', registerpincode || '');
            formData.append('membership', membershipname || '');
            formData.append('membership_type', membershiptype || '');
            formData.append('membership_number', membershipnumber || '');
            formData.append('subject', document.getElementById('MailSubject').value);
            // formData.append('email_to', document.getElementById('MailTo').value);
            formData.append('email_to', emailTo);
            // formData.append('email_cc', document.getElementById('MailCC').value);
            // formData.append('email_bcc', document.getElementById('MailBCC').value);
            formData.append('email_cc', emailCc);
            formData.append('email_bcc', emailBcc);
            formData.append('email_text', document.getElementById('MailText').value);
            formData.append('notes', document.getElementById('MailNotes').value);

            // Handle file upload
            const fileInput = document.getElementById('file-upload');

            if (fileInput && fileInput.files.length > 0) {
                for (let i = 0; i < fileInput.files.length; i++) {
                    formData.append('attachment', fileInput.files[i]);
                }
            } else {
                console.log("No files selected.");
            }

            const response = await axios.post(
                `${apiUrl}/emailtriggerdetails/`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (!response.data.success) {
                throw new Error("Failed to save data");
            }

            // Clear all fields
            // setMailTO('');
            document.getElementById('MailTo').value = '';
            document.getElementById('MailText').value = '';
            document.getElementById('MailCC').value = '';
            // document.getElementById('MailBCC').value = '';
            setMailBCC('');
            document.getElementById('MailSubject').value = '';
            document.getElementById('MailNotes').value = '';
            // fileInput.value = ''; // Clear the file input

            // Clear selected files state
            setSelectedFiles([]); // Assuming you use a state hook for files

            // Clear selected checkboxes and reset selectedData
            $(".row-select").prop("checked", false);
            $("#select-all").prop("checked", false);
            selectedData.current.clear();

            console.log("Email Trigger Data Details Saved Successfully!");
            // setIsLoader(false);
            setLoadingDialog(false);
            setOpenDialog(true);

        }
        // catch (error) {
        //     console.error("Error saving data:", error);
        //     setIsLoader(false);
        //     setFailureDialog(true);
        // }
        catch (error) {
            console.error("Error saving data:", error);
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message);
                // setIsLoader(false);
                setLoadingDialog(false);
                setFailureDialog(true); // Open the dialog on error
            } else {
                setErrorMessage('Unknown error occurred'); // Handle other types of errors
                // setIsLoader(false);
                setLoadingDialog(false);
                setFailureDialog(true); // Open the dialog on error
            }
        }
    };

    // Function to clear all form fields
    const handleClear = () => {
        setCategoryName("All");
        setRegisterCountry(null);
        setRegisterState(null);
        setRegisterCity(null);
        setRegisterPincode('');
        setMembershipName('');
        setMembershipType('');
        setMembershipNumber('');
        setMailTO('');
        // setEmailCc('');
        // setEmailBcc('');
        // setSubject('');
        // setEmailText('');
        // setNotes('');
        document.getElementById('MailText').value = '';
        document.getElementById('MailCC').value = '';
        document.getElementById('MailBCC').value = '';
        document.getElementById('MailSubject').value = '';
        document.getElementById('MailNotes').value = '';

        // Clear selected files state
        setSelectedFiles([]); // Assuming you use a state hook for files

        // Clear selected checkboxes and reset selectedData
        $(".row-select").prop("checked", false);
        $("#select-all").prop("checked", false);
        selectedData.current.clear();

    };

    return (
        <div style={{ width: "90%", backgroundColor: "white", padding: "20px", borderRadius: isMobile ? "0" : "15px", minHeight: "750px", marginTop: "120px", marginLeft: isMobile ? "3px" : "65px", marginRight: isMobile ? "3px" : "50px", marginBottom: "50px", }} >
            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="20px" >
                <Box display="flex" alignItems="center">
                    <Typography
                        variant="h4"
                        component="h2"
                        style={{
                            fontFamily: "Times New Roman",
                            fontSize: "22px",
                            color: "#003380",
                            fontWeight: "bold",
                        }}
                    >
                        Mail Sending
                    </Typography>
                    <IconButton edge="end">
                        <ForwardToInboxIcon style={{ color: "#003380", fontSize: "20px" }} />
                    </IconButton>
                </Box>
            </Box>
            <Divider />
            <Grid container spacing={2} style={{ marginTop: "35px" }}>
                <Grid item xs={6} md={1.5}>
                    <FormControl variant="outlined" required error={Boolean(errors.category_name)} fullWidth >
                        <InputLabel id="category_name-label" style={{ color: errors.category_name ? "red" : undefined }} >
                            Category Name
                        </InputLabel>
                        <Select labelId="category_name-label" id="category_name" name="category_name" value={categoryName} onChange={handleChange} label="Category Name" required >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="Customer">Customer</MenuItem>
                            <MenuItem value="Vendor">Vendor</MenuItem>
                            <MenuItem value="Agent">Agent</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={1.5}>
                    <FormControl variant="outlined" required error={Boolean(errors.country)} fullWidth >
                        <Autocomplete
                            options={countryData}
                            getOptionLabel={(option) => option.CountryName || ""}
                            value={registerCountry}
                            onChange={handleRegisterCountryChange}
                            renderInput={(params) => (
                                <TextField {...params} label="Country" />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={1.5}>
                    <FormControl variant="outlined" required error={Boolean(errors.state)} fullWidth >
                        <Autocomplete
                            disabled={registerStateField}
                            options={registerStateData}
                            getOptionLabel={(option) => option.StateName || ""}
                            value={registerState}
                            onChange={handleRegisterStateChange}
                            renderInput={(params) => <TextField {...params} label="State" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={1.5}>
                    <FormControl variant="outlined" required error={Boolean(errors.city)} fullWidth >
                        <Autocomplete
                            disabled={registerCityField}
                            options={registerCityData}
                            getOptionLabel={(option) => option.CityName || ""}
                            value={registerCity}
                            onChange={handleRegisterCityChange}
                            renderInput={(params) => <TextField {...params} label="City" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={1.5}>
                    <FormControl variant="outlined" required error={Boolean(errors.pincode)} fullWidth >
                        <Autocomplete
                            disabled={registerPincodeField}
                            options={registerPincodeData}
                            getOptionLabel={(option) => option.Pincode || ""}
                            value={registerPincodeData.find(option => option.Pincode === registerpincode) || null}
                            onChange={handleRegisterPincodeChange}
                            renderInput={(params) => <TextField {...params} label="Pincode" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={1.5}>
                    <FormControl variant="outlined" required error={Boolean(errors.membershipname)} fullWidth >
                        <Autocomplete
                            disabled={!isAgent}
                            options={membershipNameData}
                            getOptionLabel={(option) => option.MembershipName || ""}
                            value={membershipNameData.find(option => option.MembershipName === membershipname) || null}
                            onChange={handleMembershipNameChange}
                            renderInput={(params) => <TextField {...params} label="Membership Name" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={1.5}>
                    <FormControl variant="outlined" required error={Boolean(errors.membershiptype)} fullWidth >
                        <Autocomplete
                            disabled={!isAgent}
                            options={membershipTypeData}
                            getOptionLabel={(option) => option.MembershipType || ""}
                            value={membershipTypeData.find(option => option.MembershipType === membershiptype) || null}
                            onChange={handleMembershipTypeChange}
                            renderInput={(params) => <TextField {...params} label="Member Type" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={1.5}>
                    <FormControl variant="outlined" required error={Boolean(errors.membershipnumber)} fullWidth >
                        <Autocomplete
                            disabled={!isAgent}
                            options={membershipNumberData}
                            getOptionLabel={(option) => option.MembershipNumber || ""}
                            value={membershipNumberData.find(option => option.MembershipNumber === membershipnumber) || null}
                            onChange={handleMembershipNumberChange}
                            renderInput={(params) => <TextField {...params} label="Membership Number" />}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <CircularProgress color="primary" />
                </Box>
            ) : (
                <Grid container spacing={1.3} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", }} >
                    <Grid item xs={12} sm={6} md={6} style={{ textAlign: "center", marginTop: "30px", marginBottom: "auto", overflowX: "auto", }} >
                        <table ref={tableRef} className="display" style={{ width: "100%", border: "1px solid #003380" }} >
                            <thead style={{ backgroundColor: "#00004d", color: "white" }}>
                                <tr>
                                    <th>SL.No</th>
                                    <th>
                                        <input type="checkbox" id="select-all" />
                                    </th>
                                    <th>ID</th>
                                    <th>Category Type Name</th>
                                    <th>Person Name</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody></tbody>
                        </table>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ textAlign: "center", marginBottom: "auto", marginTop: "30px" }} >
                        <Box sx={{ width: "100%" }}>
                            <TextField id="MailTo" label="To" variant="outlined" multiline rows={2} fullWidth style={{ marginBottom: "20px" }} />
                            <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                                <Grid item xs={6}>
                                    <TextField id="MailCC" label="CC" variant="outlined" multiline rows={2} fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField id="MailBCC" label="BCC" variant="outlined" multiline rows={2} fullWidth value={mailBCC} onChange={(event) => setMailBCC(event.target.value)} disabled required />
                                </Grid>
                            </Grid>
                            <TextField id="MailSubject" label="Subject" variant="outlined" fullWidth style={{ marginBottom: "20px" }} required />
                            <TextField id="MailText" label="Email Text" variant="outlined" multiline rows={8} fullWidth required />
                        </Box>
                    </Grid>
                </Grid>
            )}

            <Grid container spacing={1.3} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", }} >
                {/* <Grid item xs={12} sm={6} md={6} style={{ textAlign: "center", marginTop: "30px", maxWidth: isMobile ? "380px" : "100%", overflowX: "auto", }} >
                    <input type="file" id="Attachment" multiple />
                </Grid> */}

                <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'center', marginTop: '30px' }}>
                    <FileInputContainer>
                        <TypographyWithIcon variant="h6" color="textPrimary">
                            <UploadIcon style={{ fontSize: '3rem' }} />
                            Upload Files
                        </TypographyWithIcon>
                        <label htmlFor="file-upload" style={{ marginTop: "-30px" }}>
                            <UploadButton variant="contained" component="span" endIcon={<UploadFileIcon />}>
                                Choose Files
                            </UploadButton>
                        </label>
                        <FileInput
                            id="file-upload"
                            type="file"
                            multiple
                            onChange={handleFileChange}
                        />
                        {selectedFiles.length > 0 && (
                            <Typography variant="body2" color="textSecondary" style={{ marginTop: '10px' }}>
                                {selectedFiles.length} file(s) selected
                            </Typography>
                        )}
                    </FileInputContainer>
                </Grid>

                <Grid item xs={12} sm={6} md={6} style={{ textAlign: "center", marginBottom: "auto", marginTop: "30px" }} >
                    <Box sx={{ width: "100%" }}>
                        <TextField id="MailNotes" label="Notes" variant="outlined" multiline rows={8} fullWidth />
                    </Box>
                </Grid>
            </Grid>

            {/* <Box display="flex" justifyContent="center" marginTop="50px" marginBottom="20px">
                <Button variant="contained" color="primary" onClick={handleSave} style={{ marginRight: "10px" }} > Save </Button>
                <Button variant="contained" color="primary" > Clear </Button>
            </Box> */}

            <Box display="flex" justifyContent="center" marginTop="50px" marginBottom="20px">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    style={{ marginRight: "10px" }}
                    // endIcon={<SaveIcon />}
                    endIcon={<SendIcon />}
                >
                    Send
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClear}
                    endIcon={<ClearIcon />}
                >
                    Clear
                </Button>
            </Box>

            <Dialog open={openDialog} onClose={handleCancelled} ref={dialogRef} style={{ zIndex: 1300, backdropFilter: "blur(5px)" }} disableBackdropClick disableEscapeKeyDown>
                <DialogTitle>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={SuccessIcon} alt="Success Icon" style={{ marginRight: '10px', width: '200px', height: '100px', marginLeft: '50px' }} />
                    </span>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        {"Email Trigger Data Saved Successfully!"}
                    </Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button onClick={handleCancelled} color="primary" variant="contained" autoFocus sx={{ py: 0.4 }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={failureDialog} onClose={handleFailureClose} ref={failureRef} style={{ zIndex: 1300, backdropFilter: "blur(5px)" }} >
                <DialogTitle>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={ErrorIcon} alt="Success Icon" style={{ marginRight: '10px', width: '200px', height: '100px', marginLeft: '20px' }} />
                    </span>
                </DialogTitle>
                <DialogContent>
                    <Typography sx={{ textAlign: "center" }}>
                        {/* {"Email Trigger Data Saved Failure!"} */}
                        {errorMessage || "Email Trigger Data Saved Failure!"}
                    </Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button onClick={handleFailureClose} color="primary" variant="contained" autoFocus sx={{ py: 0.4 }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* {isLoader && (
                <>
                    <div style={{ position: "fixed", left: 0, top: 0, width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1000, }} />
                    <div style={{ position: "absolute", left: "50%", top: "90%", transform: "translate(-50%, -50%)", display: "flex", alignItems: "center", }} >
                        <CircularProgress size={45} style={{ marginRight: 8 }} />
                    </div>
                </>
            )} */}

            {/* <Dialog
                open={loadingDialog}
                onClose={handleFailureClose}
                style={{ zIndex: 1300, backdropFilter: "blur(5px)" }}
            >
                <DialogContent style={{ textAlign: 'center', position: 'relative' }}>
                    {loadingDialog && (
                        <>
                            <div style={{ position: "fixed", left: 0, top: 0, width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1000, }} />
                            <div style={{ position: "absolute", left: "50%", top: "90%", transform: "translate(-50%, -50%)", display: "flex", alignItems: "center", }} >
                                <CircularProgress size={45} style={{ marginRight: 8 }} />
                            </div>
                        </>
                    )}
                </DialogContent>
            </Dialog> */}

            <Dialog
                open={loadingDialog}
                onClose={handleFailureClose}
                style={{ zIndex: 1300, backdropFilter: "blur(5px)" }}
            >
                <DialogContent style={{ textAlign: 'center', position: 'relative', padding: '24px' }}>
                    {loadingDialog && (
                        <>
                            {/* Background overlay */}
                            <div
                                style={{
                                    position: "absolute",
                                    left: 0,
                                    top: 0,
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                    zIndex: 1100, // Ensure this is below the content but above the dialog's background
                                    borderRadius: '4px' // Optional: match the dialog's border radius
                                }}
                            />
                            {/* Spinner */}
                            <div
                                style={{
                                    position: "relative",
                                    zIndex: 1200, // Ensure this is above the background overlay
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%"
                                }}
                            >
                                <CircularProgress size={45} style={{ marginRight: 8, color: 'white' }} />
                            </div>
                        </>
                    )}
                </DialogContent>
            </Dialog>


        </div>
    );
};

export default Email;
