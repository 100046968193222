import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import Login from "./Pages/LoginPage/LoginPage";
import { Alert, Slide, Snackbar } from "@mui/material";
import Preloader from "./Components/Preloader";


function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

function CustomSnackbar({ open, handleClose, message, snackbarColor }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{ mt: 1 }}
      open={open}
      autoHideDuration={2000}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
    >
      <Alert
        onClose={handleClose}
        severity={snackbarColor}
        sx={{ width: "100%" }}
      >
        <span style={{ marginRight: "8px" }}>{message}</span>
      </Alert>
    </Snackbar>
  );
}

const App = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState("success");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the timeout duration as needed

    return () => clearTimeout(timer);
  }, []);

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Login
                  setSnackbarMessage={setSnackbarMessage}
                  setSnackbarColor={setSnackbarColor}
                  setOpenSnackbar={setOpenSnackbar}
                />
              }
            />
            <Route path="/dashboard" element={<Dashboard />} />
          </Routes>
        </Router>
      )}

      <CustomSnackbar
        open={openSnackbar}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        snackbarColor={snackbarColor}
      />
    </>
  );
};

export default App;
