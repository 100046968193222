import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Provider } from 'react-redux';
import store from './Redux/store';


function disableRightClick(event) {
  event.preventDefault();
}

const theme = createTheme();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <div onContextMenu={disableRightClick}> 
     <Provider store={store}>
      <App/>
    </Provider> 
    </div>
  </ThemeProvider>
);

