const initialState = {
    user: {
      address: "",
      city: "",
      country: "",
      email: "",
      first_name: "",
      last_name: "",
      password: "",
      phone_number: "",
      pincode: "",
      role: "",
      state: "",
    },
  };
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_USER':
        return {
          ...state,
          user: action.payload,
        };
      case 'CLEAR_USER':
        return {
          ...state,
          user: initialState.user,
        };
      default:
        return state;
    }
  };
  
  export default userReducer;