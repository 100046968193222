import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Button,
  Typography,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  Grid,TextField
} from "@mui/material";
import $ from "jquery";
import "datatables.net";
import "datatables.net-buttons-dt";
import "jszip";
import "pdfmake";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import axios from "axios";
import { apiUrl } from "../../apiConfig";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import StateDetails from "./StateDetails";
import { useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const State = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [isNewContentVisible, setIsNewContentVisible] = useState(true);
  const tableRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [countryData, setCountryData] = useState([]);
  const [filterCountry, setFilterCountry] = useState(null);
  const fetchData = useCallback(async (state_name) => {
    try {
      console.log(state_name);
      const response = await axios.post(
        `${apiUrl}/statemaster/`,
        {
          apitype: "Get",
          country_name: state_name?  state_name?.CountryName : "India",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const responseData = response.data;
      setData(responseData.data);
      setLoading(false);
      console.log(responseData);
    } catch (error) {
      console.log("Error fetching data: " + error.message);
      setLoading(false);
    }
  }, [accessToken]);

  const handleDelete = useCallback(
    async (stateId) => {
      try {
        const response = await axios.post(
          `${apiUrl}/statemaster/`,
          {
            apitype: "Delete",
            state_id: stateId,
            status: "0",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (!response.data.success) {
          throw new Error("Failed to delete data");
        }

        fetchData();
      } catch (error) {
        console.error("Error deleting data:", error);
      }
    },
    [accessToken,fetchData]
  );
  const fetchDataCountry = useCallback(async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/countrymaster/`,
        {
          apitype: "Get",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const responseData = response.data;
      setCountryData(responseData.data);
    } catch (error) {
      console.log("Error fetching data: " + error.message);
    }
  }, [accessToken]);
  const handleEdit = (item) => {
    setSelectedItem(item);
    setIsNewContentVisible(false);
  };
  useEffect(() => {
    fetchDataCountry();
  }, [fetchDataCountry]);
  const handleDeleteConfirmation = useCallback((item) => {
    setSelectedItem(item);
    setIsDeleteDialogOpen(true);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!loading && $.fn.DataTable.isDataTable(tableRef.current)) {
      const dataTable = $(tableRef.current).DataTable();
      dataTable.clear().rows.add(data).draw();
    }
  }, [data, loading]);

  useEffect(() => {
    if (
      !loading &&
      tableRef.current &&
      !$.fn.DataTable.isDataTable(tableRef.current)
    ) {
      $(tableRef.current).DataTable({
        data: data,
        colReorder: true,
        layout: {
          topStart: "search",
          topEnd: "buttons",
        },
        buttons: [
          { extend: "copy", className: "primary-button" },
          { extend: "csv", className: "primary-button" },
          { extend: "excel", className: "primary-button" },
          { extend: "pdf", className: "primary-button" },
          { extend: "print", className: "primary-button" },
        ],
        language: {
          search: "",
          searchPlaceholder: "Search...",
        },
        columns: [
          {
            data: null,
            title: "Sl No",
            width: "5%",
            render: (data, type, row, meta) => meta.row + 1,
          },
          { data: "StateId", title: "ID", width: "5%", visible: false },
          { data: "StateName", title: "State Name", width: "20%" },
          { data: "StateCode", title: "State Code", width: "10%" },
          { data: "CountryCode", title: "Country Code", width: "10%" },
          { data: "CountryName", title: "Country Name", width: "10%" },
          {
            data: null,
            title: "Action",
            width: "15%",
            render: (data, type, row) => {
              return `
                                <button class="edit-btn" data-id="${row.StateId}"><i class="material-icons">edit</i></button>
                                <button class="delete-btn" data-id="${row.StateId}"><i class="material-icons">delete</i></button>
                            `;
            },
          },
        ],
        rowCallback: function (row, data, index) {
          const backgroundColor = index % 2 === 0 ? "#e6f2ff" : "#ffffff";
          const hoverColor = "#80bfff";
          $(row)
            .css({
              "background-color": backgroundColor,
              transition: "background-color 0.3s ease",
              cursor: "pointer",
            })
            .hover(
              function () {
                $(this).css("background-color", hoverColor);
              },
              function () {
                $(this).css("background-color", backgroundColor);
              }
            );
        },
        drawCallback: function () {
          const currentData = this.api().rows().data().toArray();
          $(".edit-btn").on("click", function () {
            const id = $(this).data("id");
            const item = currentData.find((d) => d.StateId === id);
            handleEdit(item);
          });

          $(".delete-btn").on("click", function () {
            const id = $(this).data("id");
            const item = currentData.find((d) => d.StateId === id);
            handleDeleteConfirmation(item);
          });
        },
      });


      $(".primary-button").css({
        "background-color": "#007bff",
        color: "white",
      });

      $('.dataTables_filter input[type="search"]').addClass(
        "MuiInputBase-input"
      );
    }
  }, [loading, data, handleDeleteConfirmation]);

  const handleDeleteConfirmed = () => {
    handleDelete(selectedItem.StateId);
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteCancelled = () => {
    setIsDeleteDialogOpen(false);
  };


  const handleNewButtonClick = () => {
    setSelectedItem(null);
    // setIsModalOpen(true);
    setIsNewContentVisible(false);
  };
  const handleCityDetailsClose = () => {
    setIsNewContentVisible(true);
    fetchData();
  };

  const handleCountryChange = (event, value) => {
    if (value) {
      setFilterCountry(value);
    } else {
      setFilterCountry(null);
    }
  
    console.log(value); 
  };

  return (
    <>
      {isNewContentVisible && (
        <div
          style={{
            width: "90%",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: isMobile ? "0" : "15px",
            minHeight: "750px",
            marginTop: "120px",
            marginLeft: isMobile ? "3px" : "65px",
            marginRight: isMobile ? "3px" : "50px",
            marginBottom: "50px",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom="20px"
          >
            <Typography
              variant="h4"
              component="h2"
              style={{
                fontFamily: "Times New Roman",
                fontSize: "22px",
                color: "rgb(94, 53, 177)",
                fontWeight: "bold",
              }}
            >
              State
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewButtonClick}
            >
              <AddCircleOutlineIcon sx={{ mr: 1 }} />
              New
            </Button>
          </Box>
          <Divider style={{ marginBottom: "20px" }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Autocomplete
                size="small"
                autoFocus
                key={filterCountry ? filterCountry.CountryId : null}
                options={countryData}
                getOptionLabel={(option) => option.CountryName || ""}
                value={filterCountry}
                onChange={(event, value) => handleCountryChange(event, value)}
                renderInput={(params) => (
                  <TextField {...params} label="Country" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
            <Grid item xs="auto">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={()=> fetchData(filterCountry)}
                  size="small"
                  sx={{ marginRight: "10px", marginTop: "5px" }}
                >
                  <FilterAltIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <div
            style={{
              marginTop: "20px",
              maxWidth: isMobile ? "340px" : "100%",
              overflowX: "auto",
            }}
          >
            <table
              ref={tableRef}
              className="display"
              style={{ width: "100%", border: "1px solid #003380" }}
            >
              <thead style={{ backgroundColor: "#00004d", color: "white" }}>
                <tr>
                  <th>Sl No</th>
                  <th>ID</th>
                  <th>State Name</th>
                  <th>State Code</th>
                  <th>Country Code</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      )}
      {!isNewContentVisible && (
        <StateDetails onClose={handleCityDetailsClose} selectedItems={selectedItem} />
      )}
      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancelled}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this item?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmed} color="error">
            Yes
          </Button>
          <Button onClick={handleDeleteCancelled} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default State;
