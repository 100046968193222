import { useTheme } from "@emotion/react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from "@material-ui/core";
import {
  Box,
  Button,
  Divider,
  TextField,
  Grid,
  Typography,
  Autocomplete,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { apiUrl } from "../../apiConfig";
import axios from "axios";
import { useSelector } from "react-redux";
const initialUserData = {
  first_name: "",
  last_name: "",
  password: "",
  confirmPassword: "",
  email: "",
  phone_number: "",
  role: "",
  country: "",
  address: "",
  city: "",
  state: "",
  pincode: "",
};
function UserRegistrationDetails({ modelType, selectedItems, onClose }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedItem, setSelectedItem] = useState(
    selectedItems || initialUserData
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [save, setSave] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [hideDelButton, setHideDelButton] = useState(false);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [countries, setCountries] = useState([]);
  const [error, setError] = useState({
    first_name: "",
    last_name: "",
    password: "",
    confirmPassword: "",
    email: "",
    phone_number: "",
    role: "",
    country: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
  });
  const fetchCountry = useCallback(async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/countrymaster/`,
        {
          apitype: "Get",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const responseData = response.data;
      setCountries(responseData.data);
    } catch (error) {
      console.log("Error fetching data: " + error.message);
    }
  }, [accessToken]);
  useEffect(() => {
    setHideDelButton(modelType);
    console.log(modelType);
    fetchCountry();
  }, [hideDelButton, modelType, selectedItems, fetchCountry]);

  const handleSave = async (e, stay = false) => {
    console.log(selectedItem);
    e.preventDefault();
    try {
      const requestData = {
        first_name: selectedItem.first_name,
        last_name: selectedItem.last_name,
        phone_number: selectedItem.phone_number,
        address: selectedItem.address,
        email: selectedItem.email,
        country: selectedItem.country,
        role: selectedItem.role,
        state: selectedItem.state,
        city: selectedItem.city,
        pincode: selectedItem.pincode,
        password: selectedItem.password,
      };
      console.log(requestData);

      const response = await axios.post(`${apiUrl}/register/`, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.data.status !== "200") {
        throw new Error("Failed to save data");
      }

      setSuccessMessage("New User Added Successfully");
      setIsDialogOpen(true);
      setSave(true);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };
  const handleDelete = useCallback(
    async (userId) => {
      try {
        const response = await axios.post(
          `${apiUrl}/deleteusers/`,
          {
            id: userId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        onClose(false);
        if (!response.data.success) {
          throw new Error("Failed to delete data");
        }
      } catch (error) {
        console.error("Error deleting data:", error);
      }
    },
    [onClose, accessToken]
  );
  const handleUpdateSaveFn = (e) => {
    if (selectedItems && selectedItems.id) {
      handleUpdateUser(e);
    } else {
      handleSave(e, false);
    }
  };
  const handleUpdateUser = async (e) => {
    console.log("Update");
    e.preventDefault();
    try {
      const response = await axios.post(
        `${apiUrl}/updateusers/`,
        {
          id: selectedItem.id,
          first_name: selectedItem.first_name,
          last_name: selectedItem.last_name,
          phone_number: selectedItem.phone_number,
          address: selectedItem.address,
          email: selectedItem.email,
          country: selectedItem.country,
          role: selectedItem.role,
          state: selectedItem.state,
          city: selectedItem.city,
          pincode: selectedItem.pincode,
          password: selectedItem.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.data.status !== 200) {
        throw new Error("Failed to save data");
      }

      setSuccessMessage("User Updated Successfully");
      setIsDialogOpen(true);
      setSave(true);
    } catch (e) {}
  };
  const handleCancelled = () => {
    setIsDialogOpen(false);
    if (save) {
      onClose(false);
    }
    setSave(false);
  };
  const handleDeleteCancelled = () => {
    setIsDeleteDialogOpen(false);
  };
  const handleDeleteConfirm = () => {
    setIsDeleteDialogOpen(true);
  };
  const handleDeleteConfirmed = () => {
    handleDelete(selectedItem.id);
  };
  const handleClose = () => {
    onClose(false);
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setSelectedItem((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const handleBlur = (e) => {
    const { value } = e.target;
    if (value !== selectedItem.password) {
      setError((prevState) => ({
        ...prevState,
        password: "Password and confirm password do not match",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        password: "",
      }));
    }
  };

  return (
    <div
      style={{
        width: "90%",
        backgroundColor: "white",
        padding: "20px",
        borderRadius: isMobile ? "0" : "15px",
        minHeight: "420px",
        marginTop: "120px",
        marginLeft: isMobile ? "3px" : "65px",
        marginRight: isMobile ? "3px" : "50px",
        marginBottom: "10px",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <Typography
          variant="h4"
          component="h2"
          style={{
            fontFamily: "Times New Roman",
            fontSize: "22px",
            color: "rgb(94, 53, 177)",
            fontWeight: "bold",
          }}
        >
          User Registration
        </Typography>
      </Box>

      <Divider style={{ marginBottom: "20px" }} />
      <Typography
        variant="form"
        component={"form"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Grid
          container
          spacing={1.6}
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ width: "70%" }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="First Name"
              id="first_name"
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.first_name}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Last Name"
              id="last_name"
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.last_name}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Email"
              id="email"
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.email}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Password"
              id="password"
              variant="outlined"
              size="small"
              fullWidth
              disabled={selectedItem && selectedItem.id ? true : false}
              style={{ marginBottom: "20px" }}
              type="password"
              onChange={handleChange}
              value={selectedItem.password}
              autoComplete="new-password"
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Confirm Password"
              id="confirmPassword"
              variant="outlined"
              size="small"
              fullWidth
              disabled={selectedItem && selectedItem.id ? true : false}
              onBlur={handleBlur}
              style={{ marginBottom: "20px" }}
              type="password"
              autoComplete="new-password"
              onChange={handleChange}
              value={selectedItem.confirmPassword}
              error={!!error.password}
              helperText={error.password}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Phone Number"
              id="phone_number"
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.phone_number}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Role"
              id="role"
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.role}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <TextField
              label="Address"
              id="address"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.address}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
              disablePortal
              label="Country"
              id="country"
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.country}
              required
              options={top100Films}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  onChange={handleChange}
                  value={selectedItem.country}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              disablePortal
              label="City"
              id="city"
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.city}
              required
              options={top100Films}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="City"
                  onChange={handleChange}
                  value={selectedItem.city}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              disablePortal
              label="State"
              id="state"
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.state}
              required
              options={top100Films}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  value={selectedItem.state}
                  onChange={handleChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Pincode"
              id="pincode"
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginBottom: "20px" }}
              onChange={handleChange}
              value={selectedItem.pincode}
              required
            />
          </Grid>
        </Grid>
      </Typography>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        {hideDelButton && (
          <Button
            variant="contained"
            sx={{ marginLeft: "10px", marginTop: "10px" }}
            onClick={handleDeleteConfirm}
          >
            Delete
          </Button>
        )}
        {!hideDelButton && (
          <Button
            variant="contained"
            sx={{ marginLeft: "10px", marginTop: "10px" }}
            // onClick={(e) => handleSave(e, false)}
            onClick={handleUpdateSaveFn}
          >
            Save
          </Button>
        )}
        <Button
          variant="contained"
          sx={{ marginLeft: "10px", marginTop: "10px" }}
          color="error"
          onClick={handleClose}
        >
          Close
        </Button>
      </div>
      <Dialog open={isDialogOpen} onClose={handleCancelled}>
        <DialogContent>
          <Typography>{successMessage}</Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleCancelled} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancelled}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this item?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmed} color="error">
            Yes
          </Button>
          <Button onClick={handleDeleteCancelled} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UserRegistrationDetails;
const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 },
  {
    label: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { label: "The Good, the Bad and the Ugly", year: 1966 },
  { label: "Fight Club", year: 1999 },
  {
    label: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    label: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
  { label: "Forrest Gump", year: 1994 },
  { label: "Inception", year: 2010 },
  {
    label: "The Lord of the Rings: The Two Towers",
    year: 2002,
  },
  { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { label: "Goodfellas", year: 1990 },
  { label: "The Matrix", year: 1999 },
  { label: "Seven Samurai", year: 1954 },
  {
    label: "Star Wars: Episode IV - A New Hope",
    year: 1977,
  },
  { label: "City of God", year: 2002 },
  { label: "Se7en", year: 1995 },
  { label: "The Silence of the Lambs", year: 1991 },
  { label: "It's a Wonderful Life", year: 1946 },
  { label: "Life Is Beautiful", year: 1997 },
  { label: "The Usual Suspects", year: 1995 },
  { label: "Léon: The Professional", year: 1994 },
  { label: "Spirited Away", year: 2001 },
  { label: "Saving Private Ryan", year: 1998 },
  { label: "Once Upon a Time in the West", year: 1968 },
  { label: "American History X", year: 1998 },
  { label: "Interstellar", year: 2014 },
  { label: "Casablanca", year: 1942 },
  { label: "City Lights", year: 1931 },
  { label: "Psycho", year: 1960 },
  { label: "The Green Mile", year: 1999 },
  { label: "The Intouchables", year: 2011 },
  { label: "Modern Times", year: 1936 },
  { label: "Raiders of the Lost Ark", year: 1981 },
  { label: "Rear Window", year: 1954 },
  { label: "The Pianist", year: 2002 },
  { label: "The Departed", year: 2006 },
  { label: "Terminator 2: Judgment Day", year: 1991 },
  { label: "Back to the Future", year: 1985 },
  { label: "Whiplash", year: 2014 },
  { label: "Gladiator", year: 2000 },
  { label: "Memento", year: 2000 },
  { label: "The Prestige", year: 2006 },
  { label: "The Lion King", year: 1994 },
  { label: "Apocalypse Now", year: 1979 },
  { label: "Alien", year: 1979 },
  { label: "Sunset Boulevard", year: 1950 },
  {
    label:
      "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
    year: 1964,
  },
  { label: "The Great Dictator", year: 1940 },
  { label: "Cinema Paradiso", year: 1988 },
  { label: "The Lives of Others", year: 2006 },
  { label: "Grave of the Fireflies", year: 1988 },
  { label: "Paths of Glory", year: 1957 },
  { label: "Django Unchained", year: 2012 },
  { label: "The Shining", year: 1980 },
  { label: "WALL·E", year: 2008 },
  { label: "American Beauty", year: 1999 },
  { label: "The Dark Knight Rises", year: 2012 },
  { label: "Princess Mononoke", year: 1997 },
  { label: "Aliens", year: 1986 },
  { label: "Oldboy", year: 2003 },
  { label: "Once Upon a Time in America", year: 1984 },
  { label: "Witness for the Prosecution", year: 1957 },
  { label: "Das Boot", year: 1981 },
  { label: "Citizen Kane", year: 1941 },
  { label: "North by Northwest", year: 1959 },
  { label: "Vertigo", year: 1958 },
  {
    label: "Star Wars: Episode VI - Return of the Jedi",
    year: 1983,
  },
  { label: "Reservoir Dogs", year: 1992 },
  { label: "Braveheart", year: 1995 },
  { label: "M", year: 1931 },
  { label: "Requiem for a Dream", year: 2000 },
  { label: "Amélie", year: 2001 },
  { label: "A Clockwork Orange", year: 1971 },
  { label: "Like Stars on Earth", year: 2007 },
  { label: "Taxi Driver", year: 1976 },
  { label: "Lawrence of Arabia", year: 1962 },
  { label: "Double Indemnity", year: 1944 },
  {
    label: "Eternal Sunshine of the Spotless Mind",
    year: 2004,
  },
  { label: "Amadeus", year: 1984 },
  { label: "To Kill a Mockingbird", year: 1962 },
  { label: "Toy Story 3", year: 2010 },
  { label: "Logan", year: 2017 },
  { label: "Full Metal Jacket", year: 1987 },
  { label: "Dangal", year: 2016 },
  { label: "The Sting", year: 1973 },
  { label: "2001: A Space Odyssey", year: 1968 },
  { label: "Singin' in the Rain", year: 1952 },
  { label: "Toy Story", year: 1995 },
  { label: "Bicycle Thieves", year: 1948 },
  { label: "The Kid", year: 1921 },
  { label: "Inglourious Basterds", year: 2009 },
  { label: "Snatch", year: 2000 },
  { label: "3 Idiots", year: 2009 },
  { label: "Monty Python and the Holy Grail", year: 1975 },
];
